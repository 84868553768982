import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Clientes from './pages/clientes/index';
import Main from './pages/main/index';
import Notification from './pages/notification/index';
import Catalogo from './pages/catalogo/index';
import Login from './pages/Login/index';
import Relacionamento from './pages/relacionamento';
import CriaUsuario from './pages/criarUsuario/index'

const Routers =  () => (
    // <BrowserRouter>
    //     <Switch>
    //         <Route exact path="/" component={Login} />
    //         <Route path="/produtos" component={Main} />
    //         <Route path="/notificacao/" component={Notification}/>
    //         <Route path="/catalogo/" component={Catalogo} />
    //     </Switch>
    // </BrowserRouter>
    <BrowserRouter>
        <Switch>
            <PrivateCliente authed={getItem()} exact path="/"/>
            <Route path="/login" component={Login} />
            <PrivateCliente authed={getItem()} path="/clientes"/>
            <PrivateProduto authed={getItem()} path="/produtos" />
            <PrivateNotificacao authed={getItem()} path="/notificacao/"/>
            <PrivateCatalogo authed={getItem()} path="/catalogo/" />
            <PrivateRelacionamento authed={getItem()} path="/relacionamento-direcionado/"/>
            <PrivateUsuario authed={getItem()} path="/criar-usuario/"/>
        </Switch>
    </BrowserRouter>
);


function getItem () {
    return localStorage.getItem("loged");
}

function getRoot() {
  return localStorage.getItem("root");
}

function PrivateCliente ({component: Component, authed}) {
  
    return (
      <Route
        render={(props) => authed === "true"
          ? <Clientes />
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

function PrivateCatalogo ({component: Component, authed}) {
    return (
      <Route
        render={(props) => authed === "true"
          ? <Catalogo />
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

  function PrivateUsuario ({component: Component, authed}) {
    console.log(getRoot());
    
    return (
      <Route
        render={(props) => authed === "true"
          ? getRoot() == 'true' ? (<CriaUsuario />):(<Redirect to={{pathname: '/clientes'}} />)
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

  function PrivateProduto ({component: Component, authed}) {
    
    return (
      <Route
        render={(props) => authed === "true"
          ? <Main />
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

  function PrivateNotificacao ({component: Component, authed}) {
    return (
      <Route
        render={(props) => authed === "true"
          ? <Notification />
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

  function PrivateRelacionamento ({component: Component, authed}) {
    return (
      <Route
        render={(props) => authed === "true"
          ? <Relacionamento />
          : <Redirect to={{pathname: '/login'}} />}
      />
    )
  }

export default Routers;