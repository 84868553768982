import React from 'react';

import './styles.css';

import Routers from './routers';

const PaginaInicial = () => (
    <div>
      <Routers />
    </div>
);

export default PaginaInicial;
