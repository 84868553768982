import React, {Component} from 'react';
import Header from '../../components/header/index';
import FormularioUsuario from '../../components/formCriaUsuario';
import TabelaUsuarios from '../../components/tabelaUsuarios/index';
import Firebase from '../../Firebase';

import { Col, Row } from 'reactstrap';

export default class criaUsuario extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            usuarios: [],
            atualizando: false,
            usuarioEditando : null
        }
        this.listaUsuarios();
    }

    salvaUsuario = async (e) => {
        e.preventDefault();
        var login = e.target.login.value.trim();
        var senha = e.target.senha.value.trim();
        var quemUsa = e.target.quemUsa.value.trim();
        var root = e.target.root.value == 'true';

        if (login != '' && senha != '') {
            const db = await Firebase.firestore();
            db.collection("administrador").doc(login).set({
            quemUsa: quemUsa,
            login: login,
            senha: senha,
            root: root
        }).then( () => {
            this.listaUsuarios();
            document.getElementById('form1').reset();
        });
        }
    }

    atualizaUsuario = async (e) => {
        e.preventDefault();
        var login = e.target.login2.value.trim();
        var senha = e.target.senha2.value.trim();
        var quemUsa = e.target.quemUsa2.value.trim();
        
        var root = e.target.root2.value == 'true';
        

        if (login != '' && senha != '') {
            const db = await Firebase.firestore();
            db.collection("administrador").doc(this.state.usuarioEditando.login).update({
            quemUsa: quemUsa,
            login: login,
            senha: senha,
            root: root
        }).then( () => {
            this.listaUsuarios();
            this.setState({atualizando: false, usuarioEditando: null});
            document.getElementById('quemUsa').value = '';
            document.getElementById('login').value = '';
            document.getElementById('senha').value = '';
        });
        }
    }

    mudaAtualizar = (usuario) => {
        this.setState({atualizando: true, usuarioEditando: usuario});
    }

    listaUsuarios = async () => {
        const db = Firebase.firestore();

        const usuarios = [];
        await db.collection('administrador').get()
        .then(snapshot => {
        snapshot.forEach(doc =>{
            usuarios.push(doc.data());
        });
        });
        await this.setState({usuarios});
    }

    removeFromFirestore = async (productDelete) => {
        const db = await Firebase.firestore();
        db.collection("administrador").doc(productDelete.login).delete().then( () => {
            this.listaUsuarios();
        });
    }

    render(){
        return(
            <div>
                <Col xs="11" sm="12">
                    <Header />
                </Col>
                <Row>
                    <Col xs="12" sm="12">
                        <FormularioUsuario atualizando={this.state.atualizando} salvaUsuario={this.salvaUsuario}
                        atualizaUsuario={this.atualizaUsuario} usuarioEditando={this.state.usuarioEditando}/>
                    </Col>
                   
                </Row>
                <Row>
                    <Col xs="12" sm="12">
                        <TabelaUsuarios usuarios={this.state.usuarios} mudaAtualizar={this.mudaAtualizar}
                        removeFromFirestore={this.removeFromFirestore}/>
                    </Col>
                </Row>
            </div>
        )
    }
}