import React, { Component } from 'react';
import { 
    Badge, Button, Card, CardBody, CardHeader, Col,Modal, ModalHeader, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import './styles.css';

class BottomTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
          modal: false
        };
    
        this.toggle = this.toggle.bind(this);
    }
    state ={
        product : []
    }

    toggle() {
        
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        
    }

    changeState = (product) => {
        this.setState({product})
        this.toggle();
    }

    render() {
        const tour = this.props.tour;
        const tourLength = tour.length;
        const babyRoom = this.props.babyRoom;
        const babyRoomLength = babyRoom.length;
        const feeding = this.props.feeding;
        const feedingLength = feeding.length;
        const accessories = this.props.accessories;
        const accessoriesLength = accessories.length;
        const clothing = this.props.clothing;
        const clothingLength = clothing.length;
        const mom = this.props.mom;
        const momLength = mom.length;
        const several = this.props.several;
        const severalLength = several.length;
        //const updatedProducts = this.props.updatedProducts;
        //const length = updatedProducts.length;
        
        return (
            
            <div id="mainCard" className="mainCardBottomTable">
                <Row>
                    <Col xs="12" lg="12">
                        <Card>
                            <CardHeader className="cardHeaderBottomTable" id="card-header">
                                <i className="fa fa-align-justify"></i><strong> Lista de Itens </strong>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Quantidade</th>
                                            <th>Descrição</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Quartinho do Bebê</td>
                                        </tr>{/*
                                        length ? (
                                        updatedProducts.map(product => {
                                            return(
                                                <tr>
                                                    <td>{product.name}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{product.price}</td>
                                                    <td><Badge onClick={ () => this.props.removeRow(product.id, updatedProducts)}>Remover</Badge></td>
                                                </tr>
                                        );
                                        })) :  ( */

                                        babyRoomLength ? (
                                            babyRoom.map(product => {
                                                return (
                                                    <tr>
                                                        <td>{product.name}</td>
                                                        <td>{product.quantity}</td>
                                                        <td>{product.description}</td>
                                                        <td>
                                                            <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                Atualizar
                                                            </Badge> &nbsp;&nbsp; 
                                                            <Badge className="badgeButton" color="danger"  onClick={ () => this.changeState(product)}>
                                                                Remover
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) :  (
                                            <tr>
                                                <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                            </tr>
                                        )
                                        }
                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Alimentação</td>
                                        </tr>
                                        {
                                            feedingLength ? (
                                                feeding.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger" onClick={ () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                                </tr>
                                            )
                                        }
                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Passeio</td>
                                        </tr>
                                        {
                                            tourLength ? (
                                                tour.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={  () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                                </tr>
                                            )
                                        }
                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Vestuário</td>
                                        </tr>
                                        {
                                            clothingLength ? (
                                                clothing.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={  () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoriaaa</td>
                                                </tr>
                                            )
                                        }
                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Acessórios</td>
                                        </tr>
                                        {
                                            accessoriesLength ? (
                                                accessories.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={  () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                                </tr>
                                            )
                                        }

                                        <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Mamãe</td>
                                        </tr>
                                        {
                                            momLength ? (
                                                mom.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={  () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                                </tr>
                                            )
                                        }

                                         <tr className="trCategory">
                                            <td id="tdColspan" className="tdCategory" colSpan="5">Diversos</td>
                                        </tr>
                                        {
                                            severalLength ? (
                                                several.map( product => {
                                                    return (
                                                        <tr>
                                                            <td>{product.name}</td>
                                                                <td>{product.quantity}</td>
                                                            <td>{product.description}</td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.insertDataForm(product) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={  () => this.changeState(product) }>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td id="tdColspan" colSpan="5">Nenhum Produto Nessa Categoria</td>
                                                </tr>
                                            )
                                        }
                            </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Confirmar Exclusão?</ModalHeader>
                    <ModalBody>
                        Você deseja excluir este produto da lista?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.props.removeFromFirestore(this.state.product); this.toggle()} }>Excluir</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default BottomTable;