import React, { Component } from 'react';
import Firebase from '../../Firebase';
import {
    Button, Card, CardBody, CardHeader, Col, Row, Table, Input, Form, Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import SkeletonLoader from 'tiny-skeleton-loader-react';

import Header from '../../components/header/index';
import './styles.css';
import noProfilePicture from '../../images/noprofilepicture.jpg';
import ModalInformcoes from '../../components/modalInformacoes/index';
import XLSX from 'xlsx';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class Clientes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            solicitacoes: [],
            solicitacoesAux: [],
            usuarioClicado: {},
            modal: false,
            tipoPesquisa: 'nome',
            isLoading: false,
            numeroClientes: 0,
            isLoadingCompleteCsv: false
        };
        this.getData(null);
        //this.getDataAux();
    }

    //   getDataAux = async () => {

    //     const db = Firebase.firestore();

    //     const listaAdmin = [];
    //     await db.collection('ListaSugestoes').get()
    //     .then(snapshot => {
    //     snapshot.forEach(doc =>{
    //         listaAdmin.push(doc.data());
    //     });
    //     });
    //     var listaCorreta = [];
    //     var listasugerida = JSON.parse(listaAdmin[0].listaSugerida);

    //     for (let i = 0; i < listasugerida.length; i++) {
    //         listasugerida[i].currentQuantity = 0;
    //     }

    //     console.log(listaCorreta);
    //     // var a  = JSON.stringify(listasugerida);
    //     // console.log(listaCorreta);
    //     // db.collection('ListaSugestoes').doc('ListaSugerida').update({
    //     //     listaSugerida: listaCorreta
    //     // })


    //     const listaFace = [];
    //     await db.collection('usersFacebook').get()
    //     .then(snapshot => {
    //     snapshot.forEach(doc =>{
    //         listaFace.push(doc.data());
    //     });
    //     });
    //     console.log(listaFace);
    //     var aaa = listasugerida;

    //     listaFace.map(usuario => {
    //         if (usuario.suggestedList != 'primeiro acesso') {
    //             for (let i = 0; i < aaa.length; i++) {
    //                 aaa[i].currentQuantity = 0;
    //             }

    //             db.collection('usersFacebook').doc((usuario.userId).toString()).update({
    //                 suggestedList: JSON.stringify(aaa)
    //             })
    //             console.log(aaa);
    //         }



    //     })


    // usuariosFace.map(usuario => {
    //     if (usuario.suggestedList != 'primeiro acesso') {
    //         var lista = JSON.parse(usuario.suggestedList);
    //         for (let i = 0; i < lista.length; i++) {
    //             if (lista[i].category === 'mamae' || lista[i].category === 'diversos') {
    //                 delete lista[i];
    //             }
    //         }
    //         console.log(lista);

    //     }
    // })

    //}

    getData = async (event) => {
        if (event) {
            event.persist();
        } 
        this.setState({isLoading: true})
        const db = Firebase.firestore();

        const solicitacoes = [];
        console.log(event);

        var tipo = await this.state.tipoPesquisa;

        if (tipo == "bb-masculino" || tipo == "bb-feminino" || tipo == 'indefinido') {
            var tipoFacebook = "sixth";
            var tipoNativo = "fifth";
            if (tipo == 'indefinido') {
                var busca = "indefinido";
            } else {
                var busca = tipo == "bb-masculino" ? "masculino" : "feminino";
            }
            
                
            await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });

                });

            await db.collection('usersNative').orderBy(tipoNativo).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });

                });

                var solicitacoesOrdenadas = solicitacoes.sort(function (a, b) {
                    return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
                });
    
                this.setState({ solicitacoes: solicitacoesOrdenadas, solicitacoesAux: solicitacoesOrdenadas, numeroClientes: solicitacoes.length, isLoading: false })
        }else if (event && event.target.value.length > 3) {

            //console.log(event.target.value.length);
            var busca = event.target.value;
            console.log(busca);
            var tipoFacebook = "";
            var tipoNativo = "";
            if (tipo == "nome") {
                tipoFacebook = "name";
                tipoNativo = "name";
            }else if (tipo == "email") {
                tipoFacebook = "third";
                tipoNativo = "emailUsual";
            }else{
                tipoFacebook = "name";
                tipoNativo = "name";
            }

            var buscaMaiuscula = busca.toLowerCase().split(" ");
            for (var a = 0; a < buscaMaiuscula.length; a++) {
                if (buscaMaiuscula[a]) {
                    var w = buscaMaiuscula[a];
                    buscaMaiuscula[a] = w[0].toUpperCase() + w.slice(1);
                }
            }
            buscaMaiuscula = buscaMaiuscula.join(" ")
            //console.log(buscaMaiuscula);

            await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });

                });

            await db.collection('usersNative').orderBy(tipoNativo).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });

                });

            if (busca != buscaMaiuscula && tipo != "bb") {
                await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(buscaMaiuscula).endAt(buscaMaiuscula + '\uf8ff').get()
                    .then(snapshot => {
                        console.log(snapshot);

                        snapshot.forEach(doc => {
                            solicitacoes.push(doc.data());
                        });

                    });

                await db.collection('usersNative').orderBy(tipoNativo).startAt(buscaMaiuscula).endAt(buscaMaiuscula + '\uf8ff').get()
                    .then(snapshot => {
                        console.log(snapshot);

                        snapshot.forEach(doc => {
                            solicitacoes.push(doc.data());
                        });

                    });
            }

            var solicitacoesOrdenadas = solicitacoes.sort(function (a, b) {
                return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
            });

            this.setState({ solicitacoes: solicitacoesOrdenadas, solicitacoesAux: solicitacoesOrdenadas, numeroClientes: solicitacoes.length, isLoading: false })
        } else if (!event || event.target.value.length == 0) {
            console.log(event);
            var numeroClientes = 0;
            await db.collection('info_geral').doc('users').get()
            .then(doc => {
                // console.log(doc.data());
                numeroClientes = doc.data().total;
            });

            await db.collection('usersFacebook').limit(25).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });
                });

            await db.collection('usersNative').limit(25).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        solicitacoes.push(doc.data());
                    });
                });
            var solicitacoesOrdenadas = solicitacoes.sort(function (a, b) {
                return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
            });
            this.setState({ solicitacoes: solicitacoesOrdenadas, solicitacoesAux: solicitacoesOrdenadas, numeroClientes, isLoading: false })
        }
    }

    toggle = (usuario) => {

        this.setState(prevState => ({
            modal: !prevState.modal,
            usuarioClicado: usuario
        }));

    }

    toggle2 = () => {

        this.setState(prevState => ({
            modal: !prevState.modal,
        }));

    }

    exportCsv = () => {
        var allUsers = this.state.solicitacoes;
        var csvRow = [['Nome', 'Email', 'Telefone', 'Data de Nascimento', 'Nome do Bebê', 'Previsão de Nascimento do Bebê', 'Sexo do Bebê', 'Primeiro Login', 'Último Login']];

        allUsers.forEach(user => {
            if (user.initialDate) {
                var data = new Date(user.initialDate.seconds * 1000);
            } else {
                var data = new Date();
            }

            var dataDia = data.getDate();
            var dataMes = data.getMonth() + 1;
            var dataAno = data.getFullYear();
            var dataHora = data.getHours();
            var dataMinutos = data.getMinutes();
            var dataSegundos = data.getSeconds();

            if (dataMes <= 9) {
                dataMes = "0" + dataMes;
            }
            if (dataDia < 10) {
                dataDia = "0" + dataDia;
            }

            var dataFinal = dataDia + "/" + dataMes + "/" + dataAno + "  " + dataHora + ":" + dataMinutos + ":" + dataSegundos;

            if (user.lastLogin) {
                var dataUltimoLogin = new Date(user.lastLogin.seconds * 1000);
                var dataDia = dataUltimoLogin.getDate();
                var dataMes = dataUltimoLogin.getMonth() + 1;
                var dataAno = dataUltimoLogin.getFullYear();
                var dataHora = dataUltimoLogin.getHours();
                var dataMinutos = dataUltimoLogin.getMinutes();
                var dataSegundos = dataUltimoLogin.getSeconds();

                if (dataMes <= 9) {
                    dataMes = "0" + dataMes;
                }
                if (dataDia < 10) {
                    dataDia = "0" + dataDia;
                }

                var dataUltimoLoginFinal = dataDia + "/" + dataMes + "/" + dataAno + "  " + dataHora + ":" + dataMinutos + ":" + dataSegundos;

            } else {
                var dataUltimoLoginFinal = dataFinal;
            }

            if (user.userType === "facebook") {
                csvRow.push([
                    user.first,
                    user.third,
                    user.fourth,
                    user.second.trim() != '' ? (user.second.split("T")[0].split("-")[2] + "/" + user.second.split("T")[0].split("-")[1] + "/" + user.second.split("T")[0].split("-")[0]) : ("Não informado"),
                    user.seventh,
                    user.fifth.split("T")[0].split("-")[2] + "/" + user.fifth.split("T")[0].split("-")[1] + "/" + user.fifth.split("T")[0].split("-")[0],
                    user.sixth,
                    dataFinal,
                    dataUltimoLoginFinal
                ]);
            } else if (user.userType === "nativo") {
                csvRow.push([
                    user.first,
                    user.emailUsual,
                    user.third,
                    user.second.split("T")[0].split("-")[2] + "/" + user.second.split("T")[0].split("-")[1] + "/" + user.second.split("T")[0].split("-")[0],
                    user.sixth,
                    user.fourth.split("T")[0].split("-")[2] + "/" + user.fourth.split("T")[0].split("-")[1] + "/" + user.fourth.split("T")[0].split("-")[0],
                    user.fifth,
                    dataFinal,
                    dataUltimoLoginFinal
                ]);
            }
        })

        var dataAtual = new Date();
        var mesAtual = dataAtual.getMonth() + 1;
        if (mesAtual < 10) {
            mesAtual = "0" + mesAtual;
        }
        var dataAtualFormatada = dataAtual.getDate() + "-" + mesAtual + "-" + dataAtual.getFullYear() + "  " + dataAtual.getHours() + "h" + dataAtual.getMinutes() + "m" + dataAtual.getSeconds() + "s";
        const ws = XLSX.utils.aoa_to_sheet(csvRow);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados do Aplicativo");
        XLSX.writeFile(wb, "Dados dos Clientes em " + dataAtualFormatada + ".xlsx");

    }

    exportCompleteCsv = async () => {
        this.setState({isLoadingCompleteCsv: true})
        var allUsers = [];

        const db = Firebase.firestore();
        var tipoFacebook = "sixth";
        var tipoNativo = "fifth";

        await db.collection('usersFacebook').orderBy(tipoFacebook).startAt('masculino').endAt('masculino' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice');
        
        await db.collection('usersFacebook').orderBy(tipoFacebook).startAt('feminino').endAt('feminino' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice2');

        await db.collection('usersFacebook').orderBy(tipoFacebook).startAt('indefinido').endAt('indefinido' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice3');

    await db.collection('usersNative').orderBy(tipoNativo).startAt('masculino').endAt('masculino' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice4');

        await db.collection('usersNative').orderBy(tipoNativo).startAt('feminino').endAt('feminino' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice5');

        await db.collection('usersNative').orderBy(tipoNativo).startAt('indefinido').endAt('indefinido' + '\uf8ff').get()
        .then(snapshot => {
            // console.log(snapshot);

            snapshot.forEach(doc => {
                allUsers.push(doc.data());
            });

        });

        console.log('nice6');

        var allUsersOrdenados = allUsers.sort(function (a, b) {
            return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
        });

        var csvRow = [['Nome', 'Email', 'Telefone', 'Data de Nascimento', 'Nome do Bebê', 'Previsão de Nascimento do Bebê', 'Sexo do Bebê', 'Primeiro Login', 'Último Login']];

        allUsersOrdenados.forEach(user => {
            if (user.initialDate) {
                var data = new Date(user.initialDate.seconds * 1000);
            } else {
                var data = new Date();
            }

            var dataDia = data.getDate();
            var dataMes = data.getMonth() + 1;
            var dataAno = data.getFullYear();
            var dataHora = data.getHours();
            var dataMinutos = data.getMinutes();
            var dataSegundos = data.getSeconds();

            if (dataMes <= 9) {
                dataMes = "0" + dataMes;
            }
            if (dataDia < 10) {
                dataDia = "0" + dataDia;
            }

            var dataFinal = dataDia + "/" + dataMes + "/" + dataAno + "  " + dataHora + ":" + dataMinutos + ":" + dataSegundos;

            if (user.lastLogin) {
                var dataUltimoLogin = new Date(user.lastLogin.seconds * 1000);
                var dataDia = dataUltimoLogin.getDate();
                var dataMes = dataUltimoLogin.getMonth() + 1;
                var dataAno = dataUltimoLogin.getFullYear();
                var dataHora = dataUltimoLogin.getHours();
                var dataMinutos = dataUltimoLogin.getMinutes();
                var dataSegundos = dataUltimoLogin.getSeconds();

                if (dataMes <= 9) {
                    dataMes = "0" + dataMes;
                }
                if (dataDia < 10) {
                    dataDia = "0" + dataDia;
                }

                var dataUltimoLoginFinal = dataDia + "/" + dataMes + "/" + dataAno + "  " + dataHora + ":" + dataMinutos + ":" + dataSegundos;

            } else {
                var dataUltimoLoginFinal = dataFinal;
            }

            if (user.userType === "facebook") {
                csvRow.push([
                    user.first,
                    user.third,
                    user.fourth,
                    user.second.trim() != '' ? (user.second.split("T")[0].split("-")[2] + "/" + user.second.split("T")[0].split("-")[1] + "/" + user.second.split("T")[0].split("-")[0]) : ("Não informado"),
                    user.seventh,
                    user.fifth.split("T")[0].split("-")[2] + "/" + user.fifth.split("T")[0].split("-")[1] + "/" + user.fifth.split("T")[0].split("-")[0],
                    user.sixth,
                    dataFinal,
                    dataUltimoLoginFinal
                ]);
            } else if (user.userType === "nativo") {
                csvRow.push([
                    user.first,
                    user.emailUsual,
                    user.third,
                    user.second.split("T")[0].split("-")[2] + "/" + user.second.split("T")[0].split("-")[1] + "/" + user.second.split("T")[0].split("-")[0],
                    user.sixth,
                    user.fourth.split("T")[0].split("-")[2] + "/" + user.fourth.split("T")[0].split("-")[1] + "/" + user.fourth.split("T")[0].split("-")[0],
                    user.fifth,
                    dataFinal,
                    dataUltimoLoginFinal
                ]);
            }
        })

        var dataAtual = new Date();
        var mesAtual = dataAtual.getMonth() + 1;
        if (mesAtual < 10) {
            mesAtual = "0" + mesAtual;
        }
        var dataAtualFormatada = dataAtual.getDate() + "-" + mesAtual + "-" + dataAtual.getFullYear() + "  " + dataAtual.getHours() + "h" + dataAtual.getMinutes() + "m" + dataAtual.getSeconds() + "s";
        const ws = XLSX.utils.aoa_to_sheet(csvRow);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados do Aplicativo");
        XLSX.writeFile(wb, "Dados dos Clientes em " + dataAtualFormatada + ".xlsx");
        this.setState({isLoadingCompleteCsv: false});
    }

    mudaTipoBusca = async (event) => {
        var evento = event.target.value;
        console.log(evento);

        await this.setState({ tipoPesquisa: event.target.value })
        if (evento == "bb-masculino" || evento == "bb-feminino" || evento == 'indefinido') {
            document.getElementById("clienteFiltro").disabled = "true";
            this.getData(null);
        }else{
            document.getElementById("clienteFiltro").disabled = false;
            this.getData(null);
        }
        
    }

    filtra = (event) => {
        console.log(event.target.value);

        // this.setState({usuariosAux: event.target.value});
        if (event.target.value.trim() === '') {
            console.log("a");
            this.setState({ solicitacoes: this.state.solicitacoesAux });
        } else {

            if (this.state.tipoPesquisa === "nome") {
                var filtrado = this.state.solicitacoesAux.filter(function (el) {

                    return el.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                });
                this.setState({ solicitacoes: filtrado });
            } else if (this.state.tipoPesquisa === "email") {
                var filtrado = this.state.solicitacoesAux.filter(function (el) {
                    if (el.userType === "nativo") {
                        return el.emailUsual.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                    } else {
                        return el.third.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                    }

                });
                this.setState({ solicitacoes: filtrado });
            } else if (this.state.tipoPesquisa === "bb") {
                var filtrado = this.state.solicitacoesAux.filter(function (el) {
                    if (el.userType === "nativo") {
                        return el.fifth.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                    } else {
                        return el.sixth.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                    }
                });
                this.setState({ solicitacoes: filtrado });
            }

        }
    }

    getDate = (date) => {
        if (date) {
            var data = new Date(date.seconds * 1000);
            var dia = data.getDate();
            var mes = data.getMonth() + 1;
            var ano = data.getFullYear();

            if (dia < 10) {
                dia = "0" + dia;
            }
            if (mes < 10) {
                mes = "0" + mes;
            }

            return dia + "/" + mes + "/" + ano;
        } else {
            return "Não informado";
        }
    }

    getLastLogin = (date, initialDate) => {
        if (date) {
            var data = new Date(date.seconds * 1000);
            var dia = data.getDate();
            var mes = data.getMonth() + 1;
            var ano = data.getFullYear();

            if (dia < 10) {
                dia = "0" + dia;
            }
            if (mes < 10) {
                mes = "0" + mes;
            }

            return dia + "/" + mes + "/" + ano;
        } else {
            if (initialDate) {
                var data = new Date(initialDate.seconds * 1000);
                var dia = data.getDate();
                var mes = data.getMonth() + 1;
                var ano = data.getFullYear();

                if (dia < 10) {
                    dia = "0" + dia;
                }
                if (mes < 10) {
                    mes = "0" + mes;
                }

                return dia + "/" + mes + "/" + ano;
            } else {
                return "Não informado";
            }
        }
    }


    render() {
        const verifica = this.state.solicitacoes;

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12">
                        <Header />
                    </Col>
                </Row>

                <Row style={{ overflowX: 'auto', margin: '0 auto' }}>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                {/* Clientes Cadastrados no Aplicativo -> { this.state.solicitacoes.length} */}
                                <div>
                                    <Form inline>
                                        <Input className="col-xs-12" onChange={this.mudaTipoBusca} type="select" name="tipoFiltroCliente" id="tipoFiltroCliente">
                                            <option value="nome">Por nome</option>
                                            <option value="email">Por email</option>
                                            <option value="bb-masculino">Por Sexo (Masculino)</option>
                                            <option value="bb-feminino">Por Sexo (Feminino)</option>
                                            <option value="indefinido">Por Sexo (Indefinido)</option>
                                        </Input>
                                        <Input className="col-xs-12 col-sm-5 col-md-5" onKeyUp={this.getData} type="text" name="clienteFiltro" id="clienteFiltro" placeholder="Digite para fazer uma filtragem" />
                                        <Label className="col-xs-12" style={{ padding: '.375rem' }}>Total = {this.state.numeroClientes}</Label>
                                    </Form>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table hover responsive className="table-outline mb-0 d-sm-table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center"><FontAwesomeIcon icon={faUsers} /></th>
                                            <th className="text-center">Cliente</th>
                                            <th className="text-center">Data de Nascimento</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Telefone</th>
                                            <th className="text-center">Primeiro Login</th>
                                            <th className="text-center">Ultimo Login</th>
                                            <th className="text-center">Ver Tudo</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                        this.state.isLoading ? (
                                            <>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='8'><SkeletonLoader /></td></tr>
                                            </>
                                            ):(
                                            
                                            verifica.length > 0 ? (
                                                Object.keys(this.state.solicitacoes).map((key) => (
                                                    <tr key={this.state.solicitacoes[key].userId}>
                                                        <td className="text-center">
                                                            <div className="avatar">
                                                                {this.state.solicitacoes[key].picture === 'assets/imgs/noprofilepicture.jpg' ?
                                                                    (<img src={noProfilePicture} className="img-avatar" alt="Foto de Perfil" />) :
                                                                    (<img src={this.state.solicitacoes[key].picture} className="img-avatar" alt="Foto de Perfil" />)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                {this.state.solicitacoes[key].first}
                                                            </div>
                                                            {/* <div className="small text-muted">
                                            <span>New</span> | Registered: Jan 1, 2015
                                        </div> */}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                this.state.solicitacoes[key].second.trim() != "" ?
                                                                    (this.state.solicitacoes[key].second.split("T")[0].split("-")[2]
                                                                        + "/" + this.state.solicitacoes[key].second.split("T")[0].split("-")[1]
                                                                        + "/" + this.state.solicitacoes[key].second.split("T")[0].split("-")[0])
                                                                    : ("Não informado")}
                                                        </td>
                                                        <td>
                                                            <div className="clearfix text-center">
                                                                {
                                                                    this.state.solicitacoes[key].userType === "facebook" ?
                                                                        (this.state.solicitacoes[key].third) :
                                                                        (this.state.solicitacoes[key].emailUsual)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="clearfix text-center">
                                                                {
                                                                    this.state.solicitacoes[key].userType === "facebook" ?
                                                                        (this.state.solicitacoes[key].fourth) :
                                                                        (this.state.solicitacoes[key].third)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    this.getDate(this.state.solicitacoes[key].initialDate)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    this.getLastLogin(this.state.solicitacoes[key].lastLogin, this.state.solicitacoes[key].initialDate)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Button active block style={{ backgroundColor: '#E80084' }} onClick={() => this.toggle(this.state.solicitacoes[key])} aria-pressed="true">Mais Detalhes</Button>
                                                        </td>
                                                    </tr>
                                                ))) : (
                                                    <tr>
                                                        <td id="tdColspan" className="nenhumCliente" colSpan="8">Nenhum cliente encontrado</td>
                                                    </tr>))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <div style={{ display: 'flex', justifyContent: 'center', width: "100%", textAlign: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <Button active style={{ backgroundColor: '#28a745' }} onClick={() => this.exportCsv()} aria-pressed="true">Exportar Dados Selecionados</Button>
                            <Button active style={{ backgroundColor: '#28a745', marginLeft: '10px', marginRight: '10px' }} onClick={() => this.exportCompleteCsv()} aria-pressed="true">Exportar Todos os dados</Button>
                            { this.state.isLoadingCompleteCsv ? <Spinner /> : ''}
                        </div>
                    </Col>
                </Row>
                <ModalInformcoes modalState={this.state.modal} toggle={this.toggle2} usuario={this.state.usuarioClicado} />
            </div>
        );
    }
}

export default Clientes;