import React, { Component } from 'react';
import { 
    Badge, Button, Card, CardBody, CardHeader, Col,Modal, ModalHeader, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import './styles.css';

import noImage from '../../images/semImagemcatalogo.png';

class BottomTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
          modal: false
        };
    
        this.toggle = this.toggle.bind(this);
    }
    state ={
        catalogoExcluir: ""
    }

    toggle() {
        
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        
    }

    changeState = (catalogoId) => {
        this.setState({catalogoExcluir: catalogoId})
        this.toggle();
    }

    render() {
        const catalogos = this.props.catalogos;
        const catalogosLength = catalogos.length;
        //const updatedProducts = this.props.updatedProducts;
        //const length = updatedProducts.length;
        //console.log(catalogos);
        
        return (
            
            <div id="mainCard" className="mainCardBottomTable">
                <Row>
                    <Col xs="12" lg="12">
                        <Card>
                            <CardHeader className="cardHeaderBottomTable" id="card-header">
                                <i className="fa fa-align-justify"></i><strong> Lista de Itens </strong>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Imagem</th>
                                            <th>Catálogo</th>
                                            <th>Fornecedor</th>
                                            <th>Data</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {/*
                                        length ? (
                                        updatedProducts.map(product => {
                                            return(
                                                <tr>
                                                    <td>{product.name}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{product.price}</td>
                                                    <td><Badge onClick={ () => this.props.removeRow(product.id, updatedProducts)}>Remover</Badge></td>
                                                </tr>
                                        );
                                        })) :  ( */

                                        catalogosLength ? (
                                            catalogos.map(product => {
                                                return (
                                                    <tr>
                                                        <td>{product.imageFileDownloadUrl && product.imageFileDownloadUrl != "" ? 
                                                        (<img style={{height: '40px', width: '40px', borderRadius: '20%'}} src={product.imageFileDownloadUrl} className="img-avatar" alt="Imagem Do Catálogo" />)
                                                        :(<img style={{height: '40px', width: '40px', borderRadius: '20%'}} src={noImage} className="img-avatar" alt="Imagem Do Catálogo" />)}</td>
                                                        <td>{product.title}</td>
                                                        <td>{product.fornecedor}</td>
                                                        <td>{product.data}</td>
                                                        <td>
                                                            <Badge className="badgeButton" color="success" onClick={ ()=> window.open(product.fileDownloadUrl) }>
                                                                Baixar
                                                            </Badge> &nbsp;&nbsp; 
                                                            <Badge className="badgeButton" color="danger"  onClick={ () => this.changeState(product.id)}>
                                                                Remover
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) :  (
                                            <tr>
                                                <td id="tdColspan" colSpan="5">Nenhum Catálogo Cadastrado</td>
                                            </tr>
                                        )
                                        }
                                       
                            </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Confirmar Exclusão?</ModalHeader>
                    <ModalBody>
                        Você deseja excluir este catálogo da lista?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.props.removerCatalogo(this.state.catalogoExcluir); this.toggle()} }>Excluir</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default BottomTable;