import React, { Component } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import './styles.css';
import Check from '../../images/CheckRosa.png';

class FormularioCatalogo extends Component {

  render() {
    return (
      <div id="mainDiv">
        <Row>
          <Col xs="12" sm="12">
            <Form id="formNotification" onSubmit={this.props.handleSubmit}>
              <Card id="mainCard">
                <CardHeader id="card-header">
                  <strong>Enviar Novo Catálogo</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup row className="my-0">
                    <Col sm="6" xs="12" md="6">
                      <FormGroup>
                        <Label htmlFor="titleCatalogo">Título do Catálogo</Label>
                        <Input type="text" id="titleCatalogo" name="titleCatalogo" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" xs="12" md="6">
                      <FormGroup>
                        <Label htmlFor="empresaCatalogo">Nome do Fornecedor</Label>
                        <Input type="text" id="empresaCatalogo" name="empresaCatalogo" />
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup row style={{ display: "flex" }}>
                    <FormGroup style={{ display: "flex" }} className="my-0 col-xs-12 col-md-6">
                      <Col style={{ marginTop: "20px" }} className="col-xs-12 col-md-8">
                        <Label>Escolha o arquivo de catálogo</Label>
                      </Col>
                      <Col style={{ marginTop: "20px" }} className="col-xs-12 col-md-4">
                        <Input type="file" accept="application/pdf" name="catalogoFile" id="catalogoFile" className="file_customizada" onChange={this.props.getFile} />
                        <Label for="catalogoFile"><Badge color="danger">Escolher Catálogo</Badge></Label>
                      </Col>
                    </FormGroup>

                    <FormGroup style={{ display: "flex" }} className="my-0 col-xs-12 col-md-6">
                      <Col className="col-xs-12 col-md-8" style={{ marginTop: "20px" }}>
                        <Label>Escolha uma imagem que represente o catálogo</Label>
                      </Col>
                      <Col className="col-xs-12 col-md-4" style={{ marginTop: "20px" }}>
                        <Input type="file" accept="image/*" name="imagemCatalogoFile" id="imagemCatalogoFile" className="file_customizada" onChange={this.props.getImage} />
                        <Label for="imagemCatalogoFile"><Badge color="danger">Escolher Imagem</Badge></Label>
                      </Col>
                    </FormGroup>
                  </FormGroup>

                  <FormGroup style={{ display: "flex" }}>
                    <Col className="col-xs-12 col-md-6">
                      <FormGroup>
                        <div className="divFile">
                          {
                            this.props.fileBase64 ? (
                              <div className="divInsideFile">
                                <img className="fileUpload" alt="" src={Check}></img>
                                <label>Catálogo Carregado</label>
                              </div>
                            ) : (
                                <label className="labelAvisoFile">Nenhum Catálogo Carregado</label>
                              )
                          }

                        </div>
                      </FormGroup>
                    </Col>

                    <Col className="col-xs-12 col-md-6">
                      <FormGroup>
                        <div className="divFile">
                          {
                            this.props.imageBase64 ? (
                              <div className="divInsideFile">
                                <img className="fileUpload" alt="" src={this.props.imageBase64}></img>
                              </div>
                            ) : (
                                <label className="labelAvisoFile">Nenhuma Imagem Carregada</label>
                              )
                          }

                        </div>
                      </FormGroup>
                    </Col>
                  </FormGroup>

                </CardBody>
                <CardFooter id="cardFooter">
                  <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i> Enviar </Button>
                  <Button type="reset" size="md" id="buttonReset" onClick={this.props.resetForm} ><i className="fa fa-ban"></i> Apagar </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormularioCatalogo;