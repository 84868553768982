import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,} from 'reactstrap';

    import './styles.css';

    class FormularioNotificacaoDirect extends Component {
      
      render(){
        return(
          <div id="mainDiv">
            <Row>
            <Col xs="12" sm="12">
              <Form id="formNotification" onSubmit={this.props.enviaNotificacoesDirecionadas}>
                <Card id="mainCard">
                  <CardHeader id="card-header">
                   <strong>Enviar notificação para {this.props.numeroClientesAtual} {this.props.numeroClientesAtual > 1 ? " clientes": " cliente"}</strong>
                  </CardHeader>
                  <CardBody>
                    <FormGroup row className="my-0">

                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="titleNews">Título da Notícia</Label>
                          <Input type="text" required id="titleNews" name="titleNews"/>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="shortDescription">Breve Descrição</Label>
                          <Input required type="text" id="shortDescription" name="shortDescription"/>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row >
                      <Col xs="12" md="12">
                        <Label>Texto Completo da Mensagem</Label>
                        <Input type="textarea" required id="completeDescription" rows="5"
                          placeholder="Descrição..." name="completeDescription"/>
                      </Col>
                    </FormGroup>
                    <FormGroup row >
                      <Col xs="12" md="6">
                        <Label>Escolha uma Imagem para a Mensagem</Label>
                      </Col>
                      <Col xs="12" md="6">
                        <Input type="file" accept="image/gif, image/png, image/jpeg, image/bmp, image/webp" name="newsImage" id="newsImage" className="file_customizada" onChange={this.props.getImage} />
                        <Label for="newsImage"><Badge color="danger">Escolher Arquivo</Badge></Label>
                      </Col>
                      <Col xs="12" md="12">
                        <div className="divImagem">
                          {
                            this.props.imageBase64 ? (
                              <img className="imagemUpload" alt="" src={this.props.imageBase64}></img>
                            ) : (
                              <label className="labelAvisoImagem">Nenhuma Imagem Carregada</label>
                            )
                          }
                          
                        </div>
                        
                      </Col>
                    </FormGroup>                  
                  </CardBody>
                  <CardFooter id="cardFooter">
                    <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i>Enviar Mensagem</Button>
                    <Button type="reset" color="danger" size="md" className="buttonReset"><i className="fa fa-ban"></i> Apagar </Button>
                    <Button type="reset" size="md" style={{backgroundColor: '#E80084'}} className="buttonReset" onClick={this.props.exibeTabela}><i className="fa fa-ban"></i> Voltar Para Tabela </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
        );
      }
    }

    export default FormularioNotificacaoDirect;