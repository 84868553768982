
import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Label } from 'reactstrap';

class ModalInformcoes extends Component {

  render() {
    const usuario = this.props.usuario;
    /*Inicio Definir data inicial*/
    var meses = ["Janeiro","Fevereiro", "Março", "Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
    if (usuario.initialDate) {
        var data = new Date(usuario.initialDate.seconds * 1000);
    }else{
        var data = new Date();
    }
    var dataDia = data.getDate();
    var dataMes = data.getMonth();
    var dataAno = data.getFullYear();
    var dataHora = data.getHours();
    var dataMinutos = data.getMinutes();
    var dataSegundos = data.getSeconds();

    var dataFinal = "Dia "+dataDia+" de "+meses[dataMes]+" de "+dataAno+" às "+dataHora+"h"+dataMinutos+"m"+dataSegundos+"s" ;
    /*Fim Definir data inicial*/

    /*Iniciao Definir data do último Login*/
    if (usuario.lastLogin) {
        var data = new Date(usuario.lastLogin.seconds * 1000);
        var dataDia = data.getDate();
        var dataMes = data.getMonth();
        var dataAno = data.getFullYear();
        var dataHora = data.getHours();
        var dataMinutos = data.getMinutes();
        var dataSegundos = data.getSeconds();

        var dataUltimoLogin = "Dia "+dataDia+" de "+meses[dataMes]+" de "+dataAno+" às "+dataHora+"h"+dataMinutos+"m"+dataSegundos+"s" ;
    }else{
        var dataUltimoLogin = dataFinal;
    }
    
    /*Fim Definir data do último Login*/
    return (
      <div>
        <Modal isOpen={this.props.modalState} toggle={this.props.toggle}>
          <ModalHeader style={{textAlign: 'center'}} toggle={this.props.toggle}>Todas as informações sobre {this.props.usuario.first}</ModalHeader>
          <ModalBody>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Nome:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{usuario.first}</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Email:&nbsp;&nbsp;&nbsp; </Label>
                    {
                        usuario.userType === "facebook" ? 
                        (<Label>{usuario.third}</Label>):
                        (<Label>{usuario.emailUsual}</Label>)
                    }   
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Telefone:&nbsp;&nbsp;&nbsp; </Label>
                    {
                        usuario.userType === "facebook" ? 
                        (<Label>{usuario.fourth}</Label>):
                        (<Label>{usuario.third}</Label>)
                    }
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Data de Nascimento:&nbsp;&nbsp;&nbsp; </Label>
                
                        <Label>{
                            usuario.second && usuario.second.trim() != "" ? 
                            (usuario.second.split("T")[0].split("-")[2]+"/"+usuario.second.split("T")[0].split("-")[1]+"/"+usuario.second.split("T")[0].split("-")[0]):
                            ("")
                        }</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Nome do Bebê:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{usuario.userType === "facebook" ? 
                            (usuario.seventh):
                            (usuario.sixth)}</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Data de Nascimento do Bebê:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{
                            usuario.userType === "facebook" ? 
                            (usuario.fifth ? 
                                (usuario.fifth.split("T")[0].split("-")[2]+"/"+usuario.fifth.split("T")[0].split("-")[1]+"/"+usuario.fifth.split("T")[0].split("-")[0]):
                                ("")
                            ):
                            (usuario.fourth ? 
                                (usuario.fourth.split("T")[0].split("-")[2]+"/"+usuario.fourth.split("T")[0].split("-")[1]+"/"+usuario.fourth.split("T")[0].split("-")[0]):
                                (""))
                        }</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Sexo do Bebê:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{usuario.userType === "facebook" ? 
                            (usuario.sixth):
                            (usuario.fifth)}</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Primeiro Login:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{dataFinal}</Label>
                </Col>
            </Row>
            <Row style={{justifyContent: "left"}} >
                <Col style={{justifyContent: "left"}}>
                    <Label>Último Login:&nbsp;&nbsp;&nbsp; </Label>
                    <Label>{dataUltimoLogin}</Label>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{justifyContent: 'center'}}>
            <Button color="primary" onClick={this.props.toggle}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalInformcoes;