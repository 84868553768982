import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,} from 'reactstrap';

    import './styles.css';

    class FormularioNotificacao extends Component {
      
      render(){
        return(
          <div id="mainDiv">
            <Row>
            <Col xs="12" sm="12">
              <Form id="formNotification" onSubmit={this.props.handleSubmit}>
                <Card id="mainCard">
                  <CardHeader id="card-header">
                    <strong>Enviar Nova Notificação</strong>
                  </CardHeader>
                  <CardBody>
                    <FormGroup row className="my-0">

                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="titleNews">Título da Notícia</Label>
                          <Input type="text" id="titleNews" name="titleNews" onChange={this.props.mudaTitulo}/>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="shortDescription">Breve Descrição</Label>
                          <Input type="text" id="shortDescription" name="shortDescription"/>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row >
                      <Col xs="12" md="12">
                        <Label>Texto Completo da Notícia</Label>
                        <Input type="textarea" id="completeDescription" rows="5"
                          placeholder="Descrição..." name="completeDescription" onChange={this.props.mudaTexto}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row >
                      <Col xs="12" md="6">
                        <Label>Escolha uma Imagem para a notícia</Label>
                      </Col>
                      <Col xs="12" md="6">
                        <Input type="file" accept="image/gif, image/png, image/jpeg, image/bmp, image/webp" name="newsImage" id="newsImage" className="file_customizada" onChange={this.props.getImage} />
                        <Label for="newsImage"><Badge color="danger">Escolher Arquivo</Badge></Label>
                      </Col>
                      <Col xs="12" md="12">
                        <div className="divImagem">
                          {
                            this.props.imageBase64 ? (
                              <img className="imagemUpload" alt="" src={this.props.imageBase64}></img>
                            ) : (
                              <label className="labelAvisoImagem">Nenhuma Imagem Carregada</label>
                            )
                          }
                          
                        </div>
                        
                      </Col>
                    </FormGroup>                  
                  </CardBody>
                  <CardFooter id="cardFooter">
                    <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i> Adicionar</Button>
                    <Button type="reset" size="md" id="buttonReset"  onClick={this.props.resetForm}><i className="fa fa-ban"></i> Apagar </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
        );
      }
    }

    export default FormularioNotificacao;