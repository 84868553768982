import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Firebase from '../../Firebase';

import Header from '../../components/header/index';
import Smartphone from '../../components/smartphone/index';
import FormularioNotificacao from '../../components/formNotification/index';


export default class Notification extends Component {

    constructor(props){
        super(props);
        this.state = {
          image: null,
          imageBase64: null,
          notification: {
            title: "",
            shortDescription: "",
            completeDescription: "",
            imageDownloadUrl: ""
          }
        }
    }

    getImage = async event => {
        await this.setState({
          image: event.target.files[0]
        });
        var fileReaded = new FileReader();
        fileReaded.readAsDataURL(this.state.image);

        fileReaded.onload = (fileReaded) => {
            this.setState({
                imageBase64: fileReaded.target.result
            });
        }  
    }

    changeStatusNotification = () => {
        this.setState({
            image: null,
            imageBase64: null,
        })
    }

    submitNotification = async (e) => {
        e.preventDefault();
        
        //Pegando os dados do formulário através do evento
        var title = e.target.titleNews.value;
        var shortDescription = e.target.shortDescription.value;
        var completeDescription = e.target.completeDescription.value;
        var now = new Date();

        //Criando referência para o firebase storage para salvar a imagem
        var storageRef = Firebase.storage().ref();
        var notificationsRef = storageRef.child('notifications/'+e.target.titleNews.value+'.jpg');
        var file = await this.state.image;
        await notificationsRef.put(file)
            .then( function(snapshot) {
            //console.log("Uploaded");
            });

        //Retornando a url para acesso da imagem
        await notificationsRef.getDownloadURL().then( async url => {
            await this.setState({
                notification: {
                    title: title,
                    shortDescription: shortDescription,
                    completeDescription: completeDescription,
                    imageDownloadUrl: url
                }
            });
        });

        const db = await Firebase.firestore();
        db.collection('noticias').add({
            title: await this.state.notification.title,
            shortDescription: await this.state.notification.shortDescription,
            completeDescription: await this.state.notification.completeDescription,
            imageDownloadUrl: await this.state.notification.imageDownloadUrl,
            dateNews: now
        })
        .then( async () => {

            var title = this.state.notification.title;
            var shortDescription = await this.state.notification.shortDescription;
            var headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Basic YjI0YjQ3YmEtNzRiMS00MWY0LTg1YzgtMjM0NWEwMDNiYjNi"
              };

            var message = { 
                app_id: "f37d9743-dcad-4a82-9344-d473490cc2b0",
                headings: {"en": title},
                contents: {"en": shortDescription},
                included_segments: ["Todos_os_usuarios"],
                data: {"direcionada": "false"}
            };

            var options = {
                 host: "onesignal.com",
                 port: 443,
                //url: urlOnesignal,
                path: "/api/v1/notifications",
                method: "POST",
                headers: headers,
                body: message
            };
            //console.log(options);
            
            var https = require('https');
            var req = await https.request(options, function (res) {
            res.on('data', function (data) {
                //console.log(data);
                
            });
            });

            req.on('error', function (e) {
                //console.log(e);
                
            });

            req.write(JSON.stringify(message));
            req.end();

            //Resetando o formulário e limpando as variável do estado
            document.getElementById("formNotification").reset();
            this.setState({
                notification: {
                    title: "",
                    shortDescription: "",
                    completeDescription: "",
                    imageDownloadUrl: ""
                },
                image: null,
                imageBase64: null,
                texto: "",
                titulo: ""
            })
        })
        .catch( error => {
            //console.log("Erro ao salvar os dados ", error);
        })
    }

    mudaTexto = (event) => {
        this.setState({
            texto: event.target.value
        })
    }

    mudaTitulo = (event) => {
        this.setState({
            titulo: event.target.value
        })
    }

    render(){
        return (
            <div>
                <Col xs="11" sm="12">
                    <Header />
                </Col>    
                <Row>
                    <Col xs="12" sm="8">
                        <FormularioNotificacao handleSubmit={this.submitNotification} getImage={this.getImage} 
                        resetForm={this.changeStatusNotification} imageBase64={this.state.imageBase64} 
                        mudaTexto={this.mudaTexto} mudaTitulo={this.mudaTitulo}/>
                    </Col>
                    <Col className="d-none d-md-flex"  sm="4">
                        <Smartphone imagem={this.state.imageBase64} texto={this.state.texto}
                        titulo={this.state.titulo}/>
                    </Col>
                </Row>
            </div>
        );
    }
}