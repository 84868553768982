import React, {Component} from 'react';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,} from 'reactstrap';

    import './styles.css';

    class FormularioUsuario extends Component {
      
      
      render(){
        var a = this.props.usuarioEditando;
        return(
          <div>
              {
                  
                  this.props.atualizando ? (
                      
                        <div id="mainDiv">
                        
                        <Row>
                        <Col xs="12" sm="12">
                            <Form id="form1" onSubmit={this.props.atualizaUsuario}>
                            <Card id="mainCard">
                                <CardHeader id="card-header">
                                <strong>Atualizar Usuário</strong>
                                </CardHeader>
                                <CardBody>
                                <FormGroup row className="my-0">
            
                                    <Col sm="3" xs="12">
                                    <FormGroup>
                                        <Label htmlFor="quemUsa2">Quem Vai Utilizar?</Label>
                                        <Input type="text" id="quemUsa2" name="quemUsa2" defaultValue={a.quemUsa}></Input>
                                    </FormGroup>
                                    </Col>
            
                                    <Col sm="3" xs="12">
                                    <FormGroup>
                                        <Label htmlFor="login2">Novo Login</Label>
                                        <Input type="text" required readOnly id="login2" name="login2" defaultValue={a.login}/>
                                    </FormGroup>
                                    </Col>
                                            
                                    <Col sm="3" xs="12">
                                    <FormGroup>
                                        <Label htmlFor="senha2">Senha Para o Novo Login</Label>
                                        <Input type="text" required id="senha2" name="senha2" defaultValue={a.senha}/>
                                    </FormGroup>
                                    </Col>

                                    <Col sm="3" xs="12">
                                    <FormGroup>
                                        <Label htmlFor="root2">É administrador?</Label>
                                        <Input type="select" required id="root2" name="root2" >
                                            <option value="false" selected>Não</option>
                                            <option value="true" >Sim</option>
                                        </Input>
                                    </FormGroup>
                                    </Col>

                                </FormGroup>             
                                </CardBody>
                                <CardFooter id="cardFooter">
                                <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i> Atualizar Usuário</Button>
                                </CardFooter>
                            </Card>
                            </Form>
                        </Col>
                        </Row>
                    </div>
                  ):(
                    <div id="mainDiv">
                
                        <Row>
                        <Col xs="12" sm="12">
                        <Form id="form1" onSubmit={this.props.salvaUsuario}>
                            <Card id="mainCard">
                            <CardHeader id="card-header">
                                <strong>Adicionar usuário</strong>
                            </CardHeader>
                            <CardBody>
                                <FormGroup row className="my-0">
            
                                <Col sm="3" xs="12">
                                    <FormGroup>
                                    <Label htmlFor="quemUsa">Quem Vai Utilizar?</Label>
                                    <Input type="text" id="quemUsa" name="quemUsa" />
                                    </FormGroup>
                                </Col>
            
                                <Col sm="3" xs="12">
                                    <FormGroup>
                                    <Label htmlFor="login">Novo Login</Label>
                                    <Input type="text" required id="login" name="login" />
                                    </FormGroup>
                                </Col>
                                        
                                <Col sm="3" xs="12">
                                    <FormGroup>
                                    <Label htmlFor="senha">Senha Para o Novo Login</Label>
                                    <Input type="password" required id="senha" name="senha" />
                                    </FormGroup>
                                </Col>

                                <Col sm="3" xs="12">
                                    <FormGroup>
                                        <Label htmlFor="root">É administrador?</Label>
                                        <Input type="select" required id="root" name="root">
                                            <option value={false} selected>Não</option>
                                            <option value={true} >Sim</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                </FormGroup>             
                            </CardBody>
                            <CardFooter id="cardFooter">
                                <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i> Adicionar Usuário</Button>
                                <Button type="reset" size="md" id="buttonReset"><i className="fa fa-ban"></i> Apagar </Button>
                            </CardFooter>
                            </Card>
                        </Form>
                        </Col>
                    </Row>
                    </div>
                  )
              }
        </div>
        );
      }
    }

    export default FormularioUsuario;