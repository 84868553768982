import React, { Component } from 'react';
import Firebase from '../../Firebase';
import {
    Button, Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Label, Input, Form
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/header/index';
import './styles.css';
import noProfilePicture from '../../images/noprofilepicture.jpg';
import SkeletonLoader from 'tiny-skeleton-loader-react';

class TableUsersDirect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usuarios: []
        };
    }


    render() {
        console.log(this.props.tipoFiltro);
        console.log(this.props.usuarios);
        
        return (
            <div>


                <Row style={{ overflowX: 'auto', margin: '0 auto' }}>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                <div>

                                    {this.props.tipoFiltro !== 'todos' ? (
                                        <Form inline>
                                            <Input className="col-xs-12" onChange={this.props.mudaTipoBuscaNormal} type="select" name="tipoFiltroCliente" id="tipoFiltroCliente">
                                                <option value="nome">Por nome</option>
                                                <option value="email">Por email</option>
                                                <option value="bb-masculino">Por Sexo (Masculino)</option>
                                                <option value="bb-feminino">Por Sexo (Feminino)</option>
                                                <option value="indefinido">Por Sexo (Indefinido)</option>
                                            </Input>
                                            <Input className="col-xs-12 col-sm-5 col-md-5" onKeyUp={this.props.filtra} type="text" name="clienteFiltro" id="clienteFiltro" placeholder="Digite para fazer uma filtragem" />
                                            <div className="col-xs-12 col-sm-5 col-md-5" style={{ marginTop: "5px" }}>
                                                <Button active style={{ backgroundColor: '#E80084' }} onClick={() => this.props.exibeFormulario(this.props.usuarios.length, 0)} aria-pressed="true">Enviar Para Todos ({this.props.usuarios.length})</Button>
                                                <Button active style={{ backgroundColor: '#28a745', marginLeft: 10 }} onClick={() => this.props.exibeTabela()} aria-pressed="true">Voltar</Button> 
                                            </div>
                                        </Form>
                                    ) : (
                                            <Form inline>
                                                <Input className="col-xs-12" onChange={this.props.mudaTipoBusca} type="select" name="tipoFiltroCliente" id="tipoFiltroCliente">
                                                    <option value="nome">Por nome</option>
                                                    <option value="email">Por email</option>
                                                    <option value="bb-masculino">Por Sexo (Masculino)</option>
                                                    <option value="bb-feminino">Por Sexo (Feminino)</option>
                                                    <option value="indefinido">Por Sexo (Indefinido)</option>
                                                </Input>
                                                <Input className="col-xs-12 col-sm-5 col-md-5" onKeyUp={this.props.filtraTodos} type="text" name="clienteFiltro" id="clienteFiltro" placeholder="Digite para fazer uma filtragem" />
                                                <div className="col-xs-12 col-sm-5 col-md-5" style={{ marginTop: "5px" }}>
                                                   { !this.props.sendToAll ? (<Button active style={{ backgroundColor: '#E80084' }} onClick={() => this.props.exibeFormulario(this.props.usuarios.length, 0)} aria-pressed="true">Enviar Para Todos ({this.props.usuarioSearchingNumber})</Button>) : ('')} 
                                                    <Button active style={{ backgroundColor: '#28a745', marginLeft: 10 }} onClick={() => this.props.exibeTabela()} aria-pressed="true">Voltar</Button> 
                                                </div>
                                            </Form>
                                        )}
 
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table hover responsive className="table-outline mb-0 d-sm-table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center"><FontAwesomeIcon icon={faUsers} /></th>
                                            <th className="text-center">Cliente</th>
                                            <th className="text-center">Data de Nascimento</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Sexo do Bebê</th>
                                            <th className="text-center">Previsão de Nascimento</th>
                                            <th className="text-center">Ver Clientes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.props.isLoading ? (
                                            <>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            </>
                                            ):(
                                            this.props.usuarios.length > 0 ? (
                                                Object.keys(this.props.usuarios).map((key) => (
                                                    
                                                    <tr key={key}>
                                                        <td className="text-center">
                                                            <div className="avatar">
                                                                {this.props.usuarios[key].picture === 'assets/imgs/noprofilepicture.jpg' ?
                                                                    (<img src={noProfilePicture} className="img-avatar" alt="Foto de Perfil" />) :
                                                                    (<img src={this.props.usuarios[key].picture} className="img-avatar" alt="Foto de Perfil" />)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                {this.props.usuarios[key].first}
                                                            </div>
                                                            {/* <div className="small text-muted">
                                            <span>New</span> | Registered: Jan 1, 2015
                                        </div> */}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                this.props.usuarios[key].second.trim() != '' ?
                                                                    (this.props.usuarios[key].second.split("T")[0].split("-")[2]
                                                                        + "/" + this.props.usuarios[key].second.split("T")[0].split("-")[1]
                                                                        + "/" + this.props.usuarios[key].second.split("T")[0].split("-")[0])
                                                                    : ("Não informado")}
                                                        </td>
                                                        <td>
                                                            <div className="clearfix text-center">
                                                                {
                                                                    this.props.usuarios[key].userType === "facebook" ?
                                                                        (this.props.usuarios[key].third) :
                                                                        (this.props.usuarios[key].emailUsual)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="clearfix text-center">
                                                                {
                                                                    this.props.usuarios[key].userType === "facebook" ?
                                                                        (this.props.usuarios[key].sixth) :
                                                                        (this.props.usuarios[key].fifth)
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="clearfix text-center">
                                                                {
                                                                    this.props.usuarios[key].userType === "facebook" ?
                                                                        (this.props.usuarios[key].fifth.split("T")[0].split("-")[2]
                                                                            + "/" + this.props.usuarios[key].fifth.split("T")[0].split("-")[1]
                                                                            + "/" + this.props.usuarios[key].fifth.split("T")[0].split("-")[0]) :
                                                                        (this.props.usuarios[key].fourth.split("T")[0].split("-")[2]
                                                                            + "/" + this.props.usuarios[key].fourth.split("T")[0].split("-")[1]
                                                                            + "/" + this.props.usuarios[key].fourth.split("T")[0].split("-")[0])
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Button active block style={{ backgroundColor: '#E80084' }} onClick={() => this.props.exibeFormulario(1, this.props.usuarios[key])} aria-pressed="true">Enviar Mensagem</Button>
                                                        </td>
                                                    </tr>
                                                ))) : (
                                                    <tr>
                                                        <td id="tdColspan" className="nenhumCliente" colSpan="7">Nenhum Cliente Neste Filtro</td>
                                                    </tr>))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <div style={{ width: "100%", textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                            <Button active style={{ backgroundColor: '#28a745' }} onClick={() => this.props.exibeTabela()} aria-pressed="true">Voltar</Button>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default TableUsersDirect;