import React, {Component} from 'react';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,} from 'reactstrap';

    import './styles.css';

    class Formulario extends Component {
      
      
      render(){
        return(
          <div id="mainDiv">
            <Row>
            <Col xs="12" sm="12">
              <Form id="form1" onSubmit={this.props.handleFormSubmit}>
                <Card id="mainCard">
                  <CardHeader id="card-header">
                    <strong>Adicionar/Atualizar Produto</strong>
                  </CardHeader>
                  <CardBody>
                    <FormGroup row className="my-0">
                      <Input type="hidden" id="productId" name="productId"/>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="productName">Nome do Produto</Label>
                          <Input type="text" required id="productName" name="productName" />
                        </FormGroup>
                      </Col>
                      
                      <Input type="hidden" value="" required id="productPrice" name="productPrice"/>
        
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="productQuantity">Quantidade</Label>
                          <Input type="text" required id="productQuantity" name="productQuantity" />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="productCategory">Categoria</Label>
                          <Input type="select" required id="productCategory" name="productCategory">
                            <option value="quartinhoBebe">Quartinho do Bebê</option>
                            <option value="alimentacao">Alimentação</option>
                            <option value="passeio">Passeio</option>
                            <option value="vestuario">Vestuário</option>
                            <option value="acessorios">Acessorios</option>
                            <option value="mamae">Mamãe</option>
                            <option value="diversos">Diversos</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">  
                      <Col xs="12" md="12">
                        <Label>Descrição do Produto</Label>
                        <Input type="textarea" id="productDescription" rows="4"
                          placeholder="Descrição..." name="productDescription" />
                      </Col>
                    </FormGroup>                  
                  </CardBody>
                  <CardFooter id="cardFooter">
                    <Button type="submit" size="md" id="buttonAdicionar"><i className="fa fa-dot-circle-o"></i> Adicionar</Button>
                    <Button type="reset" size="md" id="buttonReset" onClick={() => this.props.changeIsUpdating()}><i className="fa fa-ban"></i> Apagar </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
        );
      }
    }

    export default Formulario;