import React, { Component } from "react";
import { Row, Button, Card, CardHeader, CardBody, Col, Table } from 'reactstrap';
import Firebase from '../../Firebase';

import Header from '../../components/header/index';
import FormularioNotificacaoDirect from '../../components/formNotificationDirect/index';
import TableUsersDirect from '../../components/tableUsersDirect/index';
import { faDove } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import SkeletonLoader from 'tiny-skeleton-loader-react';

export default class Relacionamento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usuarios: [],
            usuariosAniversarioMesAtual: [],
            usuariosAniversarioAmanha: [],
            usuariosAniversarioHoje: [],
            usuariosPrevisaoDoisMeses: [],
            usuariosPrevisaoProximoMes: [],
            usuariosPrevisaoMesAtual: [],
            usuariosAniversarioMesAtualNumber: 0,
            usuariosAniversarioAmanhaNumber: 0,
            usuariosAniversarioHojeNumber: 0,
            usuariosPrevisaoDoisMesesNumber: 0,
            usuariosPrevisaoProximoMesNumber: 0,
            usuariosPrevisaoMesAtualNumber: 0,
            usuariosTodosNumber: 0,
            usuariosAniversarioMesAtualTokens: [],
            usuariosAniversarioAmanhaTokens: [],
            usuariosAniversarioHojeTokens: [],
            usuariosPrevisaoDoisMesesTokens: [],
            usuariosPrevisaoProximoMesTokens: [],
            usuariosPrevisaoMesAtualTokens: [],
            usuariosTodosTokens: [],
            usuarioClicado: {},
            modal: false,
            exibeTabela: true,
            fraseFiltro: '',
            tipoFiltro: '',
            numeroClientesAtual: 0,
            image: null,
            imageBase64: null,
            usuariosAux: [],
            exibeTabelaAux: false,
            usuariosAuxAux: [],
            tipoPesquisa: 'nome',
            tokensAtual: [],
            usuarioSearchingNumber: 0,
            isLoading: false,
            isLoadingHome: true,
            sendToAll: false
        };
        this.getData();
        //this.filtroAniversarioHoje();
    }

    getData = async () => {

        const db = Firebase.firestore();

        const usuarios = [];
        var numeroUsuarios = 0
        // console.log('vamo');

        // await db.collection('usersNative').get()
        //     .then(snapshot => {
        //         snapshot.forEach(doc => {
        //             usuarios.push([doc.data(), doc.id]);

        //         });
        //     });
        //     console.log('nice');

        // console.log(usuarios);where('auxBirthday', '>=', 1101).where('auxBirthday', '<=', 1131).

        // await db.collection('usersNative').get()
        //     .then(snapshot => {
        //         snapshot.forEach(doc => {
        //             usuarios.push(doc.data());
        //         });
        //     })

        // await db.collection('usersFacebook').get()
        //     .then(snapshot => {
        //         snapshot.forEach(doc => {
        //             usuarios.push(doc.data());
        //         });
        //     });
        await db.collection('info_geral').doc('users').get()
            .then(doc => {
                // console.log(doc.data());
                numeroUsuarios = doc.data().total;
            });

        await this.setState({ usuarios, usuariosTodosNumber: numeroUsuarios, isLoadingHome: true });
        // console.log(this.state.usuariosTodosNumber);

        // this.criaAuxiliarDataAniversario();
        ////console.log(this.state.usuarios.length);

        this.filtroAniversarioMesAtual();
        this.filtroAniversarioAmanha();
        this.filtroAniversarioHoje();
        this.filtroPrevisaoDoisMeses();
        this.filtroPrevisaoProximoMes();
        this.filtroPrevisaoMesAtual();
        // this.getTokensUsuariosAniversarioMesAtual();
        // this.getTokensUsuariosAniversarioAmanha();
        // this.getTokensUsuariosAniversarioHoje();
        // this.getTokensUsuariosPrevisaoDoisMeses();
        // this.getTokensUsuariosPrevisaoProximoMes();
        // this.getTokensUsuariosPrevisaoMesAtual();
    }

    // criaAuxiliarDataAniversario = async () => {
    //     // console.log(this.state.usuarios);
    //     var usuarios = this.state.usuarios;
    //     console.log(usuarios);
    //     const db = Firebase.firestore();
    //     usuarios.map(async (usuario) => {
    //         // console.log(usuario[0].second);
    //         if (usuario[0].fourth.trim() !== '') {
    //             // console.log(usuario[1]);

    //             // var aux = usuario[0].second.split('T')[0].split('-');
    //             // aux = aux[1].toString() + aux[2].toString();
    //             // var birthday = parseInt(aux);
    //             var aux2 = usuario[0].fourth.split('T')[0].split('-');
    //             aux2 = aux2[0].toString() + aux2[1].toString() + aux2[2].toString();
    //             var prevision = parseInt(aux2);
    //             // console.log(usuario[0].fourth);

    //             // console.log(prevision);

    //             // await db.collection("usersNative").doc(usuario[1]).update({
    //             //     // auxBirthday: birthday,
    //             //     auxPrevision: prevision
    //             // }).then( success => {
    //             //         console.log('nice');
    //             //     })
    //             //     .catch(error => {
    //             //         console.log(error);
    //             //     })
    //         }
    //     })

    //     // const db = Firebase.firestore();
    //     // db.collection("administrador").doc(this.state.usuarioEditando.login).update({}).then( success => {
    //     //     console.log('nice');

    //     // })
    //     // .catch(error => {
    //     //     console.log(error);

    //     // })
    // }

    /**
     * INICIO DAS FUNÇÕES QUE FAZ TODOS OS FILTROS, DE ANIVERSÁRIO MÊS ATUAL, AMANHÃ E HOJE,
     * E DE PREVISÃO DE NASCIMENTO DOS BEBÊS PARA DAQUI A DOIS MESES, PARA O PRÓXIMO MÊS E PARA O MÊS ATUAL
     * SALVANDO TUDO NO STADO, ASSIM COMO A QUANTIDADE DE USUÁRIOS FILTRADOS EM CADA UM DESSES FILTROS
     * PARA A CONSTRUÇÃO DA TABELA
     */
    async filtroAniversarioMesAtual() {
        var usuariosAniversarioMesAtual = [];
        var auxUsuariosAniversarioMesAtual = []
        var data = new Date();
        // console.log(data);

        var currentMonth = data.getMonth();
        var currentYear = data.getFullYear();

        var FirstDay = new Date(currentYear, currentMonth, 1);
        var LastDay = new Date(currentYear, currentMonth + 1, 0);

        var auxLastDay = LastDay.getDate();
        var stringFilterFirstDay = parseInt((currentMonth + 1).toString() + '01');
        var stringFilterLastDay = parseInt((currentMonth + 1).toString() + auxLastDay.toString());
        // console.log(stringFilterFirstDay, stringFilterLastDay);

        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxBirthday').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    auxUsuariosAniversarioMesAtual.push(doc.data());
                });

            });
        await db.collection('usersNative').orderBy('auxBirthday').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    auxUsuariosAniversarioMesAtual.push(doc.data());
                });

            });

        // console.log(auxUsuariosAniversarioMesAtual);

        await this.setState({
            usuariosAniversarioMesAtualNumber: auxUsuariosAniversarioMesAtual.length,
            usuariosAniversarioMesAtual: auxUsuariosAniversarioMesAtual, isLoadingHome: false
        })
        this.getTokensUsuariosAniversarioMesAtual();
    }

    async filtroAniversarioAmanha() {
        var usuariosAniversarioAmanha = [];

        var data = new Date();
        var currentMonth = data.getMonth();
        var currentYear = data.getFullYear();
        var currentDay = data.getDate();

        var amanha = new Date(currentYear, currentMonth, currentDay + 1);
        currentMonth = amanha.getMonth();
        currentDay = amanha.getDate();
        currentDay = currentDay;

        amanha.setHours(12);
        if (currentDay < 10) {
            currentDay = '0' + currentDay;
        } else {
            currentDay = currentDay.toString();
        }

        var stringFilterTomorrow = parseInt((currentMonth + 1).toString() + currentDay);

        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxBirthday').startAt(stringFilterTomorrow).endAt(stringFilterTomorrow).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosAniversarioAmanha.push(doc.data());
                });

            });
        await db.collection('usersNative').orderBy('auxBirthday').startAt(stringFilterTomorrow).endAt(stringFilterTomorrow).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosAniversarioAmanha.push(doc.data());
                });

            });
        // console.log(currentMonth, currentDay, stringFilterTomorrow);

        await this.setState({
            usuariosAniversarioAmanhaNumber: usuariosAniversarioAmanha.length,
            usuariosAniversarioAmanha: usuariosAniversarioAmanha, isLoadingHome: false
        })

        this.getTokensUsuariosAniversarioAmanha();
    }

    async filtroAniversarioHoje() {

        var usuariosAniversarioHoje = [];

        var data = new Date();
        var dataAtual = new Date(data.getFullYear(), data.getMonth(), data.getDate());
        var currentYear = data.getFullYear();
        //dataAtual.setHours(12);
        var currentDay = dataAtual.getDate();
        var currentMonth = dataAtual.getMonth();

        if (currentDay < 10) {
            currentDay = '0' + currentDay;
        } else {
            currentDay = currentDay.toString();
        }

        var stringFilterToday = parseInt((currentMonth + 1).toString() + currentDay);

        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxBirthday').startAt(stringFilterToday).endAt(stringFilterToday).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosAniversarioHoje.push(doc.data());
                });

            });
        await db.collection('usersNative').orderBy('auxBirthday').startAt(stringFilterToday).endAt(stringFilterToday).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosAniversarioHoje.push(doc.data());
                });

            });

        // console.log(currentDay, currentMonth, stringFilterToday);

        await this.setState({
            usuariosAniversarioHojeNumber: usuariosAniversarioHoje.length,
            usuariosAniversarioHoje: usuariosAniversarioHoje, isLoadingHome: false
        })

        this.getTokensUsuariosAniversarioHoje();
    }

    async filtroPrevisaoDoisMeses() {
        var usuariosPrevisaoDoisMeses = [];

        var data = new Date();
        var currentMonth = data.getMonth();
        var currentYear = data.getFullYear();
        var FirstDay = new Date(currentYear, currentMonth + 2, 1);
        var LastDay = new Date(currentYear, currentMonth + 3, 0);

        var auxLastDay = new Date(currentYear, currentMonth + 3, 0);
        var month = auxLastDay.getMonth() + 1;
        var year = auxLastDay.getFullYear();

        if (month < 10) {
            month = '0' + month
        } else {
            month = month.toString();
        }

        auxLastDay = LastDay.getDate();
        var stringFilterFirstDay = parseInt((year).toString() + month + '01');
        var stringFilterLastDay = parseInt((year).toString() + month + auxLastDay.toString());

        // console.log(stringFilterFirstDay, stringFilterLastDay);
        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoDoisMeses.push(doc.data());
                });
            });
        await db.collection('usersNative').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoDoisMeses.push(doc.data());
                });

            });

        await this.setState({
            usuariosPrevisaoDoisMesesNumber: usuariosPrevisaoDoisMeses.length,
            usuariosPrevisaoDoisMeses: usuariosPrevisaoDoisMeses, isLoadingHome: false
        })

        this.getTokensUsuariosPrevisaoDoisMeses();
    }

    async filtroPrevisaoProximoMes() {
        var usuariosPrevisaoProximoMes = [];

        var data = new Date();
        var currentMonth = data.getMonth();
        var currentYear = data.getFullYear();
        var FirstDay = new Date(currentYear, currentMonth + 1, 1);
        var LastDay = new Date(currentYear, currentMonth + 2, 0);

        var auxLastDay = new Date(currentYear, currentMonth + 2, 0);
        var month = auxLastDay.getMonth() + 1;
        var year = auxLastDay.getFullYear();

        if (month < 10) {
            month = '0' + month
        } else {
            month = month.toString();
        }

        auxLastDay = LastDay.getDate();
        var stringFilterFirstDay = parseInt((year).toString() + month + '01');
        var stringFilterLastDay = parseInt((year).toString() + month + auxLastDay.toString());
        // console.log(stringFilterFirstDay, stringFilterLastDay);
        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoProximoMes.push(doc.data());
                });
            });
        await db.collection('usersNative').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoProximoMes.push(doc.data());
                });

            });
        await this.setState({
            usuariosPrevisaoProximoMesNumber: usuariosPrevisaoProximoMes.length,
            usuariosPrevisaoProximoMes: usuariosPrevisaoProximoMes, isLoadingHome: false
        })

        this.getTokensUsuariosPrevisaoProximoMes();
    }

    async filtroPrevisaoMesAtual() {
        var usuariosPrevisaoMesAtual = [];

        var data = new Date();
        var currentMonth = data.getMonth();
        var currentYear = data.getFullYear();
        var FirstDay = data
        var LastDay = new Date(currentYear, currentMonth + 1, 0);
        var FirstDay = new Date(currentYear, currentMonth + 1, 1);

        var auxLastDay = new Date(currentYear, currentMonth + 1, 0);
        var month = auxLastDay.getMonth() + 1;
        var year = auxLastDay.getFullYear();

        if (month < 10) {
            month = '0' + month
        } else {
            month = month.toString();
        }

        auxLastDay = LastDay.getDate();
        var stringFilterFirstDay = parseInt((year).toString() + month + '01');
        var stringFilterLastDay = parseInt((year).toString() + month + auxLastDay.toString());

        // console.log(stringFilterFirstDay, stringFilterLastDay);
        const db = Firebase.firestore();
        await db.collection('usersFacebook').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoMesAtual.push(doc.data());
                });
            });
        await db.collection('usersNative').orderBy('auxPrevision').startAt(stringFilterFirstDay).endAt(stringFilterLastDay).get()
            .then(snapshot => {
                // console.log(snapshot);
                snapshot.forEach(doc => {
                    usuariosPrevisaoMesAtual.push(doc.data());
                });

            });

        await this.setState({
            usuariosPrevisaoMesAtualNumber: usuariosPrevisaoMesAtual.length,
            usuariosPrevisaoMesAtual: usuariosPrevisaoMesAtual, isLoadingHome: false
        })

        this.getTokensUsuariosPrevisaoMesAtual();
    }

    /**
     * FUNÇÕES QUE RECUPERAM OS TOKENS DOS USUÁRIOS QUE FORAM FILTRADOS NAS FUNÇÕES ANTERIORES
     * DE ACORDO COM O QUE O ADMINISTRADOR CLICA NOS BOTÕES 
     */
    getTokensUsuariosAniversarioMesAtual = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosAniversarioMesAtual.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosAniversarioMesAtualTokens: tokens });
    }

    getTokensUsuariosAniversarioAmanha = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosAniversarioAmanha.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosAniversarioAmanhaTokens: tokens });
    }

    getTokensUsuariosAniversarioHoje = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosAniversarioHoje.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosAniversarioHojeTokens: tokens });
    }

    getTokensUsuariosPrevisaoDoisMeses = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosPrevisaoDoisMeses.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosPrevisaoDoisMesesTokens: tokens });
    }

    getTokensUsuariosPrevisaoProximoMes = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosPrevisaoProximoMes.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosPrevisaoProximoMesTokens: tokens });
    }

    getTokensUsuariosPrevisaoMesAtual = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        this.state.usuariosPrevisaoMesAtual.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosPrevisaoMesAtualTokens: tokens });
    }

    getTokens = async () => {
        const tokens = [];
        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');

        this.state.usuarios.forEach(async usuario => {
            let idUsuario;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
            } else {
                idUsuario = usuario.email;
            }

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario);
            await usuariosRef.onSnapshot(async querySnapshot => {
                await querySnapshot.docs.forEach(docs => {
                    tokens.push(docs.data());
                })
            })
        })
        this.setState({ usuariosTodosTokens: tokens });
    }

    getImage = async event => {
        await this.setState({
            image: event.target.files[0]
        });

        var fileReaded = new FileReader();
        fileReaded.readAsDataURL(this.state.image);

        fileReaded.onload = (fileReaded) => {
            this.setState({
                imageBase64: fileReaded.target.result
            });
        }
    }

    preparaNotificacoes = () => {

    }
    enviaNotificacoesDirecionadas = async (e) => {
        e.preventDefault();
        var title = e.target.titleNews.value;
        var shortDescription = e.target.shortDescription.value;
        var completeDescription = e.target.completeDescription.value;
        var now = new Date();
        var horaCompleta = +now;
        console.log(title, shortDescription, completeDescription);

        var tokens = [];

        const db = Firebase.firestore();
        var devicesRef = await db.collection('devices');
        ////console.log(this.state.usuariosAux);
        for (const [idx, usuario] of this.state.usuariosAux.entries()) {
            console.log(usuario);

            let idUsuario;
            let nome;
            let mensagens;
            let userType;

            if (usuario.userType === 'facebook') {
                idUsuario = usuario.userId.toString();
                nome = usuario.first;
                if (usuario.mensagemStatus) {
                    mensagens = usuario.mensagemStatus;
                } else {
                    mensagens = 0;
                }
                userType = 'facebook';
            } else {
                idUsuario = usuario.email;
                nome = usuario.first;
                if (usuario.mensagemStatus) {
                    mensagens = usuario.mensagemStatus;
                } else {
                    mensagens = 0;
                }
                userType = 'nativo';
            }
            console.log(idUsuario);

            var usuariosRef = await devicesRef.where('userId', '==', idUsuario).onSnapshot(querySnapshot => {
                //  querySnapshot.docs.forEach(docs => {
                for (const [idx2, docs] of querySnapshot.docs.entries()) {
                    // console.log(docs.data());

                    var a = docs.data();
                    a.nome = nome;
                    a.mensagemStatus = mensagens;
                    a.userType = userType;
                    if (docs.data().token == '') {
                        tokens.push(a);
                        console.log(a);
                    }

                    console.log(idx, this.state.usuariosAux.length);
                    if (idx2 == querySnapshot.docs.length - 1) {
                        this.aux2(tokens, title, horaCompleta, shortDescription, completeDescription, now)
                    }
                }
                // })
            })

            console.log('a');
        }
        console.log(tokens);

    }

    async aux2(tokens, title, horaCompleta, shortDescription, completeDescription, now) {
        console.log(tokens, title, horaCompleta, shortDescription, completeDescription, now);

        //Criando referência para o firebase storage para salvar a imagem
        var imagemUrl = '';

        var storageRef = Firebase.storage().ref();
        var notificationsRef = storageRef.child('relacionamento/' + title + horaCompleta + '.jpg');
        var file = await this.state.image;
        await notificationsRef.put(file)
            .then(function (snapshot) {
                //console.log("Uploaded");
            });


        //Retornando a url para acesso da imagem
        await notificationsRef.getDownloadURL().then(async url => {
            imagemUrl = url;
        });

        const db2 = await Firebase.firestore();
        db2.collection('mensagens').add({
            titulo: title,
            textoCurto: shortDescription,
            textoCompleto: completeDescription,
            imagemDownloadUrl: imagemUrl,
            tipoMensagem: this.state.tipoFiltro,
            tokensRelacionados: tokens,
            dataMensagem: now
        })
            .then(async () => {

                var tokensOnesignal = [];

                tokens.forEach(token => {
                    tokensOnesignal.push(token.onesignal_token);
                })

                //const urlOnesignal = 'https://onesignal.com:443/api/v1/notifications';

                var headers = {
                    "Content-Type": "application/json; charset=utf-8"
                };

                var message = {
                    app_id: "f37d9743-dcad-4a82-9344-d473490cc2b0",
                    headings: { "en": title },
                    contents: { "en": shortDescription },
                    include_player_ids: tokensOnesignal,
                    data: { "direcionada": "true" }
                };

                var options = {
                    host: "onesignal.com",
                    port: 443,
                    //url: urlOnesignal,
                    path: "/api/v1/notifications",
                    method: "POST",
                    headers: headers,
                    body: message
                };
                //console.log(options);

                var https = require('https');
                var req = await https.request(options, function (res) {
                    res.on('data', function (data) {
                        //console.log(data);

                    });
                });

                req.on('error', function (e) {
                    //console.log(e);

                });

                req.write(JSON.stringify(message));
                req.end();



                //Resetando o formulário e limpando as variável do estado
                document.getElementById("formNotification").reset();
                this.setState({
                    image: null,
                    imageBase64: null
                })
            })
            .catch(error => {
                //console.log("Erro ao salvar os dados ", error);
            })

    }

    exibeTabelaDetalhes = async (tipoFiltro) => {
        var usuarios;

        if (tipoFiltro === 'aniversarioMesAtual') {
            usuarios = this.state.usuariosAniversarioMesAtual;
        } else if (tipoFiltro === 'aniversarioAmanha') {
            usuarios = this.state.usuariosAniversarioAmanha
        } else if (tipoFiltro === 'aniversarioHoje') {
            usuarios = this.state.usuariosAniversarioHoje
        } else if (tipoFiltro === 'PrevisaoDoisMeses') {
            usuarios = this.state.usuariosPrevisaoDoisMeses
        } else if (tipoFiltro === 'PrevisaoProximoMes') {
            usuarios = this.state.usuariosPrevisaoProximoMes
        } else if (tipoFiltro === 'PrevisaoMesAtual') {
            usuarios = this.state.usuariosPrevisaoMesAtual
        } else if (tipoFiltro === 'todos') {
            //usuarios = this.state.usuarios
        }

        if (tipoFiltro !== 'todos') {
            await this.setState({
                usuariosAux: usuarios, exibeTabela: false, exibeTabelaAux: true,
                usuariosAuxAux: usuarios, tipoFiltro, usuarioSearchingNumber: this.state.usuariosTodosNumber
            });
        } else {
            await this.setState({
                usuariosAux: [], exibeTabela: false, exibeTabelaAux: true,
                usuariosAuxAux: usuarios, tipoFiltro, usuarioSearchingNumber: 50
            });
            this.newFilter(null)
        }

    }

    exibeFormulario = (numeroClientesAtual, usuario) => {
        var a = [];
        a.push(usuario);
        if (usuario == 0) {
            this.setState({ exibeTabelaAux: false, numeroClientesAtual: numeroClientesAtual });
        } else {
            this.setState({ exibeTabelaAux: false, numeroClientesAtual: numeroClientesAtual, usuariosAux: a });
        }

    }

    exibeTabela = () => {
        this.setState({ exibeTabela: true });
    }

    filtra = (event) => {
        ////console.log(event.target.value);

        // this.setState({usuariosAux: event.target.value});
        if (event) {

            if (event.target.value.trim() === '') {
                ////console.log("a");
                this.setState({ usuariosAux: this.state.usuariosAuxAux });
            } else {

                if (this.state.tipoPesquisa === "nome") {
                    var filtrado = this.state.usuariosAuxAux.filter(function (el) {
                        ////console.log(el.name.toLowerCase().indexOf(event.target.value.toLowerCase()));

                        return el.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                    });
                    this.setState({ usuariosAux: filtrado });
                } else if (this.state.tipoPesquisa === "email") {
                    var filtrado = this.state.usuariosAuxAux.filter(function (el) {
                        if (el.userType === "nativo") {
                            return el.emailUsual.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                        } else {
                            return el.third.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
                        }

                    });
                    this.setState({ usuariosAux: filtrado });
                }
                ////console.log(filtrado);

            }
        } else {
            if (this.state.tipoPesquisa === "bb-feminino") {
                var filtrado = this.state.usuariosAuxAux.filter(function (el) {
                    if (el.userType === "nativo") {
                        return el.fifth.toLowerCase() == 'feminino';
                    } else {
                        return el.sixth.toLowerCase() == 'feminino';
                    }

                });
                console.log(filtrado);
                this.setState({ usuariosAux: filtrado });
            } else if (this.state.tipoPesquisa === "bb-masculino") {
                var filtrado = this.state.usuariosAuxAux.filter(function (el) {
                    if (el.userType === "nativo") {
                        return el.fifth.toLowerCase() == 'masculino';
                    } else {
                        return el.sixth.toLowerCase() == 'masculino';
                    }

                });
                console.log(filtrado);
                this.setState({ usuariosAux: filtrado });
            } else if (this.state.tipoPesquisa === "indefinido") {
                var filtrado = this.state.usuariosAuxAux.filter(function (el) {
                    if (el.userType === "nativo") {
                        return el.fifth.toLowerCase() == 'indefinido';
                    } else {
                        return el.sixth.toLowerCase() == 'indefinido';
                    }

                });
                console.log(filtrado);
                
                this.setState({ usuariosAux: filtrado });
            }
        }
    }

    mudaTipoBuscaNormal = async (event) => {
        var evento = event.target.value;
        console.log(evento);

        await this.setState({ tipoPesquisa: event.target.value })
        if (evento == "bb-masculino" || evento == "bb-feminino" || evento == 'indefinido') {
            document.getElementById("clienteFiltro").disabled = "true";
            this.filtra(null)
        } else {
            document.getElementById("clienteFiltro").disabled = false;
        }
    }

    mudaTipoBusca = async (event) => {
        var evento = event.target.value;
        console.log(evento);

        await this.setState({ tipoPesquisa: event.target.value })
        if (evento == "bb-masculino" || evento == "bb-feminino" || evento == 'indefinido') {
            document.getElementById("clienteFiltro").disabled = "true";
            this.filtraPorSexo();
        } else {
            document.getElementById("clienteFiltro").disabled = false;
            this.newFilter(null)
        }
    }

    filtraPorSexo = async () => {
        const db = Firebase.firestore();
        const usuarios = [];
        var tipoFacebook = "sixth";
        var tipoNativo = "fifth";
        var tipo = await this.state.tipoPesquisa;
        this.setState({ isLoading: true })
        if (tipo == 'indefinido') {
            var busca = 'indefinido';
        } else {
            var busca = tipo == "bb-masculino" ? "masculino" : "feminino";
        }


        await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(busca).endAt(busca + '\uf8ff').get()
            .then(snapshot => {
                console.log(snapshot);

                snapshot.forEach(doc => {
                    usuarios.push(doc.data());
                });

            });

        await db.collection('usersNative').orderBy(tipoNativo).startAt(busca).endAt(busca + '\uf8ff').get()
            .then(snapshot => {
                console.log(snapshot);

                snapshot.forEach(doc => {
                    usuarios.push(doc.data());
                });

            });

        var usuariosOrdenadas = usuarios.sort(function (a, b) {
            return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
        });
        this.setState({ usuariosAux: usuariosOrdenadas, usuarioSearchingNumber: usuariosOrdenadas.length, isLoading: false, sendToAll: false })
    }

    newFilter = async (event) => {
        if (event) {
            event.persist();
        }
        const db = Firebase.firestore();

        const usuarios = [];
        console.log(event);

        this.setState({ isLoading: true })
        var tipo = await this.state.tipoPesquisa;

        if (tipo == "bb-masculino" || tipo == "bb-feminino") {
            var tipoFacebook = "sixth";
            var tipoNativo = "fifth";
            var busca = tipo == "bb-masculino" ? "masculino" : "feminino";

            await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });

                });

            await db.collection('usersNative').orderBy(tipoNativo).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });

                });

            var usuariosOrdenadas = usuarios.sort(function (a, b) {
                return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
            });

            this.setState({ usuariosAux: usuariosOrdenadas, usuarioSearchingNumber: usuariosOrdenadas.length, isLoading: false, sendToAll: false })
        } else if (event && event.target.value.length > 3) {

            //console.log(event.target.value.length);
            var busca = event.target.value;
            console.log(busca);
            var tipoFacebook = "";
            var tipoNativo = "";
            if (tipo == "nome") {
                tipoFacebook = "name";
                tipoNativo = "name";
            } else if (tipo == "email") {
                tipoFacebook = "third";
                tipoNativo = "emailUsual";
            } else {
                tipoFacebook = "name";
                tipoNativo = "name";
            }

            var buscaMaiuscula = busca.toLowerCase().split(" ");
            console.log(buscaMaiuscula.length);

            for (var a = 0; a < buscaMaiuscula.length; a++) {
                if (buscaMaiuscula[a]) {
                    var w = buscaMaiuscula[a];
                    buscaMaiuscula[a] = w[0].toUpperCase() + w.slice(1);
                }

            }
            buscaMaiuscula = buscaMaiuscula.join(" ")
            //console.log(buscaMaiuscula);

            await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });

                });

            await db.collection('usersNative').orderBy(tipoNativo).startAt(busca).endAt(busca + '\uf8ff').get()
                .then(snapshot => {
                    console.log(snapshot);

                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });

                });

            if (busca != buscaMaiuscula && tipo != "bb") {
                await db.collection('usersFacebook').orderBy(tipoFacebook).startAt(buscaMaiuscula).endAt(buscaMaiuscula + '\uf8ff').get()
                    .then(snapshot => {
                        console.log(snapshot);

                        snapshot.forEach(doc => {
                            usuarios.push(doc.data());
                        });

                    });

                await db.collection('usersNative').orderBy(tipoNativo).startAt(buscaMaiuscula).endAt(buscaMaiuscula + '\uf8ff').get()
                    .then(snapshot => {
                        console.log(snapshot);

                        snapshot.forEach(doc => {
                            usuarios.push(doc.data());
                        });

                    });
            }

            var usuariosOrdenadas = usuarios.sort(function (a, b) {
                return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
            });

            this.setState({ usuariosAux: usuariosOrdenadas, usuarioSearchingNumber: usuariosOrdenadas.length, isLoading: false, sendToAll: false })
        } else if (!event || event.target.value.length == 0) {

            console.log(event);

            await db.collection('usersFacebook').limit(25).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });
                });

            await db.collection('usersNative').limit(25).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        usuarios.push(doc.data());
                    });
                });
            var usuariosOrdenadas = usuarios.sort(function (a, b) {
                return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : a.first.toLowerCase() > b.first.toLowerCase() ? 1 : 0;
            });
            this.setState({ usuariosAux: usuariosOrdenadas, usuarioSearchingNumber: this.state.usuariosTodosNumber, isLoading: false, sendToAll: true })
        }
    }

    updateSendToAll = () => {
        this.setState({ sendToAll: !this.state.sendToAll })
    }

    render() {
        return (
            <div>
                <Col xs="12" sm="12">
                    <Header />
                </Col>
                {this.state.exibeTabela ? (
                    <Row style={{ overflowX: 'auto', margin: '0 auto' }}>
                        <Col xs="12" sm="12">
                            <Card>
                                <CardHeader>
                                    Filtros para direcionamento de mensagens
                            </CardHeader>
                                <CardBody>
                                    <Table hover responsive className="table-outline mb-0 d-sm-table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="text-center">Tipo de Filtro</th>
                                                <th className="text-center">Quantidade</th>
                                                <th className="text-center">Ação</th>
                                            </tr>
                                        </thead>
                                        {
                                            !this.state.isLoadingHome ? (
                                            this.state.usuariosTodosNumber > 0 ? (
                                                <tbody style={{ textAlign: "center" }}>
                                                    {

                                                        this.state.usuariosAniversarioMesAtualNumber > 0 ?
                                                            (
                                                                <tr>
                                                                    <td>
                                                                        Aniversário
                                                    </td>
                                                                    <td>
                                                                        {this.state.usuariosAniversarioMesAtualNumber}{this.state.usuariosAniversarioMesAtualNumber > 1 ? (" Usuários ") : (" Usuário")} no mês atual
                                                    </td>
                                                                    <td>
                                                                        {/* <Button onClick={()=>this.exibeFormulario('Fazem Aniversário Este Mês', 'aniversarioMesAtual', this.state.usuariosAniversarioMesAtualNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}

                                                                        <Button onClick={() => this.exibeTabelaDetalhes('aniversarioMesAtual')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                    </td>
                                                                </tr>
                                                            ) : ("")
                                                    }
                                                    {this.state.usuariosAniversarioAmanhaNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Aniversário
                                                    </td>
                                                                <td>
                                                                    {this.state.usuariosAniversarioAmanhaNumber}{this.state.usuariosAniversarioAmanhaNumber > 1 ? (" Usuários ") : (" Usuário")} amanhã
                                                    </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Fazem Aniversário Amanhã','aniversarioAmanha', this.state.usuariosAniversarioAmanhaNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('aniversarioAmanha')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                    {this.state.usuariosAniversarioHojeNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Aniversário
                                                    </td>
                                                                <td>
                                                                    {this.state.usuariosAniversarioHojeNumber}{this.state.usuariosAniversarioHojeNumber > 1 ? (" Usuários ") : (" Usuário")} hoje
                                                    </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Fazem Aniversário Hoje', 'aniversarioHoje', this.state.usuariosAniversarioHojeNumber)} active  style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('aniversarioHoje')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                    {this.state.usuariosPrevisaoDoisMesesNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Previsão de Nacimento
                                                    </td>
                                                                <td>
                                                                    {this.state.usuariosPrevisaoDoisMesesNumber}{this.state.usuariosPrevisaoDoisMesesNumber > 1 ? (" Usuários ") : (" Usuário")} daqui a dois meses
                                                    </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Informaram Previsão de Nascimento Para Daqui a Dois Meses', 'PrevisaoDoisMeses', this.state.usuariosPrevisaoDoisMesesNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('PrevisaoDoisMeses')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                    {this.state.usuariosPrevisaoProximoMesNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Previsão de Nacimento
                                                    </td>
                                                                <td>
                                                                    {this.state.usuariosPrevisaoProximoMesNumber}{this.state.usuariosPrevisaoProximoMesNumber > 1 ? (" Usuários ") : (" Usuário")} no próximo mês
                                                    </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Informaram Previsão de Nascimento Para o Próximo Mês', 'PrevisaoProximoMes', this.state.usuariosPrevisaoProximoMesNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('PrevisaoProximoMes')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                    {this.state.usuariosPrevisaoMesAtualNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Previsão de Nacimento
                                            </td>
                                                                <td>
                                                                    {this.state.usuariosPrevisaoMesAtualNumber}{this.state.usuariosPrevisaoMesAtualNumber > 1 ? (" Usuários ") : (" Usuário")} no mês atual
                                            </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Informaram Previsão de Nascimento Para o Mês Atual', 'PrevisaoMesAtual', this.state.usuariosPrevisaoMesAtualNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('PrevisaoMesAtual')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                    {this.state.usuariosTodosNumber > 0 ?
                                                        (
                                                            <tr>
                                                                <td>
                                                                    Todos
                                            </td>
                                                                <td>
                                                                    {this.state.usuariosTodosNumber}{this.state.usuariosTodosNumber > 1 ? (" Usuários ") : (" Usuário")}
                                                                </td>
                                                                <td>
                                                                    {/* <Button onClick={()=>this.exibeFormulario('Enviar para todos os usuários', 'todos', this.state.usuariosTodosNumber)} active style={{backgroundColor: '#E80084'}} aria-pressed="true">Enviar Mensagens</Button> */}
                                                                    <Button onClick={() => this.exibeTabelaDetalhes('todos')} active style={{ backgroundColor: '#E80084' }} aria-pressed="true">Visualizar</Button>
                                                                </td>
                                                            </tr>
                                                        ) : ("")
                                                    }
                                                </tbody>
                                            ) : (
                                                    <tbody style={{ textAlign: "center" }}>
                                                        <tr>
                                                            <td id="tdColspan" className="nenhumCliente" colSpan="8">Carregando dados dos clientes cadastrados</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                                ) :(
                                                    <>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                                        <tr><td colSpan='7'><SkeletonLoader /></td></tr>
                                            </>
                                                )
                                        }

                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>) :
                    (this.state.exibeTabelaAux ?
                        (
                            <TableUsersDirect usuarios={this.state.usuariosAux} exibeTabela={this.exibeTabela}
                                filtra={this.filtra} mudaTipoBusca={this.mudaTipoBusca}
                                exibeFormulario={this.exibeFormulario} tipoPesquisa={this.state.tipoPesquisa} tipoFiltro={this.state.tipoFiltro}
                                filtraTodos={this.newFilter} usuarioSearchingNumber={this.state.usuarioSearchingNumber} isLoading={this.state.isLoading}
                                updateSendToAll={this.updateSendToAll} sendToAll={this.state.sendToAll} mudaTipoBuscaNormal={this.mudaTipoBuscaNormal} />
                            // <FormularioNotificacaoDirect fraseFiltro={this.state.fraseFiltro} getImage={this.getImage} 
                            // exibeTabela={this.exibeTabela} numeroClientesAtual={this.state.numeroClientesAtual}
                            // enviaNotificacoesDirecionadas={this.enviaNotificacoesDirecionadas} imageBase64={this.state.imageBase64}
                            // tipoFiltro={this.state.tipoFiltro}/>
                        ) : (
                            <FormularioNotificacaoDirect getImage={this.getImage}
                                exibeTabela={this.exibeTabela} numeroClientesAtual={this.state.numeroClientesAtual}
                                enviaNotificacoesDirecionadas={this.enviaNotificacoesDirecionadas} imageBase64={this.state.imageBase64} />
                        )
                    )
                }
            </div>
        );
    }
}