import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyAFvPbpwibs7EKoH3--9zxLWWRUfAxKTu4",
    authDomain: "aprincipal-c0cd8.firebaseapp.com",
    databaseURL: "https://aprincipal-c0cd8.firebaseio.com",
    projectId: "aprincipal-c0cd8",
    storageBucket: "aprincipal-c0cd8.appspot.com",
    messagingSenderId: "849111632310",
    appId: "1:849111632310:web:29c8dd889bc08ca2"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;