import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Firebase from '../../Firebase';

//Importando o Header, o Formulário de Cadastro de lista, a listagem de produtos adicionados/Atualizados
//e a Listagem Completa
import Header from '../../components/header/index';
import Formulario from '../../components/form/index';
import SideTable from '../../components/sideTable/index';
import BottomTable from '../../components/bottomTable/index';

class Main extends Component {

    constructor(props) {
        super(props);
        this.fillCurrentList();
    }

    state = {
        currentList: [],
        updatedProducts: [],
        isUpdating: false,
        updatingProduct: {
            name: "",
            price: "",
            quantity: "",
            category: "",
            description: ""
        },
        tour: [],
        clothing: [],
        babyRoom: [],
        feeding: [],
        accessories: [],
        mom: [],
        several: [],
        higerId: 0
    }

    componentDidMount() {
        this.initialTable();
    }

    //Busca os dados da lista de sudsdgestões atual do firestore e salva no estado do componente
    fillCurrentList = async () => {
        const db = Firebase.firestore();
        await db.collection("ListaSugestoes").doc("ListaSugerida")
            .get().then(doc => {

                if (doc.exists) {
                    //JSON.parse(doc.data().listaSugerida);
                    let currentList = JSON.parse(doc.data().listaSugerida);
                    currentList.map( item => {
                        item.status = "";
                    });
                    currentList = JSON.stringify(currentList);

                    let higerId = doc.data().higerId;
                    this.setState({
                        currentList,
                        higerId
                    });
                    this.listByCategory(currentList);
                } else {
                    console.log("Nenhum produto Cadastrado até o momento");
                }
            })
            .catch(err => {
                console.log("Error getting document", err);
            });
    }

    listByCategory = (currentList) => {
        if (currentList.length > 0) {
            let tour = [], clothing = [], babyRoom = [], feeding = [], accessories = [], mom = [], several = [];

            const vectorCurrentList = JSON.parse(currentList);
            vectorCurrentList.map(product => {

                if (product.category === "quartinhoBebe") {
                    babyRoom.push(product);
                } else if (product.category === "alimentacao") {
                    feeding.push(product);
                } else if (product.category === "passeio") {
                    tour.push(product);
                } else if (product.category === "vestuario") {
                    clothing.push(product);
                } else if (product.category === "acessorios") {
                    accessories.push(product);
                }else if (product.category === "mamae") {
                    mom.push(product);
                }else if (product.category === "diversos") {
                    several.push(product);
                }
            });

            this.setState({  tour, clothing, babyRoom, feeding, accessories, mom, several});
        }
    }

    //pega os produtos do localStorage e joga para o estado do componente para atualizar a 
    // tabela direita se tiver algum produto alterado mas não enviado ao banco
    initialTable = async () => {
        let updatedProducts = localStorage.getItem("updatedProducts");
        if (updatedProducts != null) {
            if (updatedProducts.length > 0) {
                updatedProducts = JSON.parse(localStorage.getItem("updatedProducts"));
                await this.setState({
                    updatedProducts
                });
            }
        }
    }

    //Pega os produtos que estão na lista atualmente e insere o novo produto quando o form for submetido
    atualizaEstado = async (e) => {
        e.preventDefault();
        
        let currentList = [this.state.currentList];
            if (currentList === "") {
                currentList = [];
            } else {
                currentList = JSON.parse(currentList[0]);
            }

        let updatedProducts = [...this.state.updatedProducts];
        let higerId;
        let status;
        if (this.state.isUpdating) {
            higerId = +e.target.productId.value;
            status = "updated";
        }else{
            higerId = +this.state.higerId +1; 
            status = "created";
        }
        
        //console.log(higerId);
        updatedProducts.push({
            id: higerId,
            name: e.target.productName.value,
            price: e.target.productPrice.value,
            quantity: e.target.productQuantity.value,
            category: e.target.productCategory.value,
            description: e.target.productDescription.value,
            status: status
        });

        if(this.state.isUpdating){
            await this.setState({
                isUpdating: false,
                updatedProducts
            });    
        }else{
            await this.setState({
                isUpdating: false,
                updatedProducts,
                higerId
            });
        }
        
        document.getElementById("form1").reset();

        //Atualiza a lista no localStorage
        localStorage.setItem("updatedProducts", JSON.stringify(updatedProducts));
    };

    //Remover a linha da tabela a direita quando clicar no botão remover
    removeRow = (idClicked, updatedProducts) => {
        let length = updatedProducts.length;
        if (length) {
            for (let i = 0; i <= length - 1; i++) {
                let row = updatedProducts[i];
                if (row.id == idClicked) {
                    updatedProducts.splice(i, 1);
                }
            }
            this.setState({
                updatedProducts
            })
            localStorage.setItem("updatedProducts", JSON.stringify(updatedProducts));
        }
    }

    //Inserindo/Alterando dados da lista de sugestões no firebase
    saveUpdate = async () => {
        let updatedProducts = [this.state.updatedProducts];
        
        if (updatedProducts[0].length > 0 && updatedProducts !== undefined) {
            let currentList = [this.state.currentList];
            if (currentList === "") {
                currentList = [];
            } else {
                currentList = JSON.parse(currentList[0]);
            }

            updatedProducts = updatedProducts[0];

            updatedProducts.map( (product, i) => {
                currentList.map( (productCurrent, j) => {
                    if(product.id === productCurrent.id){
                        currentList[j] = updatedProducts[i];
                        delete updatedProducts[i];
                    }
                });
            });
 
            updatedProducts.map(product => {
                currentList.push(product);
            });
            currentList = JSON.stringify(currentList);
            await this.setState({ currentList });


            const db = await Firebase.firestore();
            db.collection("ListaSugestoes").doc("ListaSugerida").set({
                listaSugerida: currentList,
                higerId: this.state.higerId
            });
            this.setState({ updatedProducts: [], currentList: [] });
            localStorage.setItem("updatedProducts", "");

            this.fillCurrentList();
        } else {

        }
    };

    //Pegando os dados da tabela de baixo e inserindo no formulário
    //quando clicar no botão atualizar na coluna ações
    insertDataForm = async (product) => {
        await this.setState({ isUpdating: true, updatingProduct: product });
        document.getElementById("productName").value = product.name;
        document.getElementById("productPrice").value = product.price;
        document.getElementById("productQuantity").value = product.quantity;
        document.getElementById("productCategory").value = product.category;
        document.getElementById("productDescription").value = product.description;
        document.getElementById("productId").value = product.id;

        document.getElementById("productName").focus();
    }

    //Mudando o valor de isUpdating no estado quando o botão apagar for presionado
    changeIsUpdating = () => {
        this.setState({isUpdating: false});
    }

    removeFromFirestore = async (productDelete) => {
        let currentList = [this.state.currentList];
        if (currentList === "") {
            currentList = [];
        } else {
            currentList = JSON.parse(currentList[0]);
        }

        currentList.map( (productCurrent, i) => {
            if( productDelete.id === productCurrent.id){
                currentList.splice(i, 1);
            }
        });
        currentList = JSON.stringify(currentList);
        await this.setState({ currentList });

        const db = await Firebase.firestore();
        db.collection("ListaSugestoes").doc("ListaSugerida").set({
            listaSugerida: currentList,
            higerId: this.state.higerId
        });
        this.setState({ updatedProducts: [], isUpdating: false });
        localStorage.setItem("updatedProducts", "");

        this.fillCurrentList();
    }

    render() {
        return (
            <div>
                <Col xs="11" sm="12">
                    <Header />
                </Col>
                <Row>
                    <Col xs="12" sm="7">
                        <Formulario handleFormSubmit={this.atualizaEstado} isUpdating={this.state.isUpdating}
                            updatingProduct={this.state.updatingProduct} changeIsUpdating={this.changeIsUpdating}/>
                    </Col>
                    <Col xs="12" sm="5">
                        <SideTable updatedProducts={this.state.updatedProducts} removeRow={this.removeRow}
                            saveUpdate={this.saveUpdate} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12">
                        <BottomTable clothing={this.state.clothing} accessories={this.state.accessories}
                            feeding={this.state.feeding} tour={this.state.tour} babyRoom={this.state.babyRoom}
                            mom={this.state.mom} several={this.state.several}
                            insertDataForm={this.insertDataForm} removeFromFirestore={this.removeFromFirestore}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Main;