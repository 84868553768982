import React, {Component} from 'react';
import { Badge, Card, CardHeader, CardBody,Table, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './styles.css';

class TableUsersDirect extends Component {

    constructor(props) {
        super(props);
        this.state = {
          modal: false
        };
    
        this.toggle = this.toggle.bind(this);
    }
    state ={
        usuario : null
    }

    toggle() {
        
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        
    }

    changeState = (usuario) => {
        this.setState({usuario})
        this.toggle();
    }

    render() {

        return (
            <div>
                <Row style={{ overflowX: 'auto', margin: '0 auto' }}>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>

                            </CardHeader>
                            <CardBody>
                                <Table hover responsive className="table-outline mb-0 d-sm-table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">Quem Usa</th>
                                            <th className="text-center">Login</th>
                                            <th className="text-center">Senha</th>
                                            <th className="text-center">Administrador</th>
                                            <th className="text-center">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{textAlign: "center"}}>
                                        {
                                            this.props.usuarios.length > 0 ? (
                                                Object.keys(this.props.usuarios).map( (key) => (
                                                   
                                                        <tr>
                                                            <td>
                                                                {this.props.usuarios[key].quemUsa}
                                                            </td>
                                                            <td>
                                                                {this.props.usuarios[key].login}
                                                            </td>
                                                            <td>
                                                                {this.props.usuarios[key].senha}
                                                            </td>
                                                            <td>
                                                                {this.props.usuarios[key].root ? ("Sim"):("Não")}
                                                            </td>
                                                            <td>
                                                                <Badge className="badgeButton" color="success" onClick={ ()=> this.props.mudaAtualizar(this.props.usuarios[key]) }>
                                                                    Atualizar
                                                                </Badge> &nbsp;&nbsp; 
                                                                <Badge className="badgeButton" color="danger"  onClick={ () => this.changeState(this.props.usuarios[key])}>
                                                                    Remover
                                                                </Badge>
                                                            </td>
                                                         </tr>
                                                    
                                            ))
                                                
                                            ):(
                                                <tr>
                                                    <td colSpan="4" style={{textAlign: "center"}}>Nenhum Usuário Encontrado</td>
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Confirmar Exclusão?</ModalHeader>
                    <ModalBody>
                        Você deseja excluir este usuário da lista?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.props.removeFromFirestore(this.state.usuario); this.toggle()} }>Excluir</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
             
        );
               
    }
}

export default TableUsersDirect;