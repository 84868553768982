import React, { Component } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table } from 'reactstrap';
import './styles.css';

class SideTable extends Component{

    render(){
        const updatedProducts = this.props.updatedProducts;
        const length = updatedProducts.length;

        return (

            <div id="mainCard">
                <Row>
                <Col  xs="12" lg="12">
                    <Card>
                        <CardHeader id="card-header">
                            <i className="fa fa-align-justify"></i><strong> Lista de Atualizações </strong>
                        </CardHeader>
                        <CardBody>
                            <Table responsive striped>
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Quantidade</th>
                                <th>Remover</th>
                            </tr>
                            </thead>
                            <tbody>{
                                length ? (
                                updatedProducts.map(product => {
                                    return(
                                        <tr>
                                            <td>{product.name}</td>
                                            <td>{product.quantity}</td>
                                            <td><Badge className="badgeButton" onClick={ () => this.props.removeRow(product.id, updatedProducts)} color="danger">Remover</Badge></td>
                                        </tr>
                                   );
                                })) :  ( <tr>
                                             <td id="tdColspan" colSpan="4">Nenhum Produto Adicionado à lista</td>
                                        </tr>)
                                }
                            </tbody>
                            </Table>
                           {/* <Pagination>
                                <PaginationItem disabled><PaginationLink previous tag="button">Prev</PaginationLink></PaginationItem>
                                <PaginationItem active>
                                    <PaginationLink tag="button">1</PaginationLink>
                                </PaginationItem>sdfsdfsd
                                <PaginationItem><PaginationLink tag="button">2</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink tag="button">3</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink tag="button">4</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink next tag="button">Next</PaginationLink></PaginationItem>
                        </Pagination>{*/}
                        </CardBody>
                        <CardFooter id="cardFooter">
                            <Button type="submit" size="md" id="buttonAdicionar" onClick={ () => this.props.saveUpdate() }><i className="fa fa-dot-circle-o"></i> Salvar</Button>
                            <Button type="reset" size="md" id="buttonReset"><i className="fa fa-ban"></i> Cancelar </Button>
                        </CardFooter>
                    </Card>
                </Col>
                </Row>
            </div>
        );
    }
}

export default SideTable;