import React, { Component } from 'react';
import './style.css';

export default class Smartphone extends Component {

    render(){
      let formatedDate = new Date();
      let day = formatedDate.getDate();
      let mounth = formatedDate.getMonth()+1;
      let year = formatedDate.getFullYear();

      if (day < 10) {
        day = '0'+day;
      }
      if (mounth < 10) {
        mounth = '0'+mounth;
      }

      let fullDate = day+'/'+mounth+'/'+year;

      let mes = fullDate.split('/')[1];
    
    let mounths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
    'Agosto', 'Setembro', 'Outrubro', 'Novembro', 'Dezembro'];

    let date = fullDate.split('/')[0]+" de "+mounths[+mes-1]+" de "+fullDate.split('/')[2];
    

        const figura = this.props.imagem;
        const titulo = this.props.titulo;
        const texto = this.props.texto;

        const noPadding = "style = 'padding: 0; margin: 0;'";

        if (figura === "" || figura == null) {
            var divImagem = "<div style='height: auto; width: 100%; text-align: center; padding-top: 20px;'>Escolha uma imagem</div>";
        }else{
            var divImagem = "<div style='height: auto; width: 100%;'><img style='height: auto; width: 100%;' src='"+figura+"'></img></div>";
        }
        if (titulo === "" || titulo == null) {
            var divTitulo = "<div style='padding-top: 10px; padding-bottom: 11px; padding-left: 16px;border-bottom: solid 1px #f1f1f1;'><label style='font-size: 22px; font-family: Cambria; font-weight: bold;'>Escolha um Título</label></div>"
            
        }else{
            var divTitulo = "<div style='padding-top: 10px; padding-bottom: 11px; padding-left: 16px;border-bottom: solid 1px #f1f1f1;'><label style='font-size: 22px; font-family: Cambria; font-weight: bold;'>"+titulo+"</label></div>"
        }
        if (texto === "" || texto == null) {
            var divTexto = "<div style='padding-top: 15px;padding-left: 16px; padding-right: 16px;'><label style='text-align: justify; word-wrap: break-word;'> Escolha um texto</label></div>"
        }else{
            var divTexto = "<div style='padding-top: 15px;padding-left: 16px; padding-right: 16px;'><label style='text-align: justify; word-wrap: break-word;'>"+texto+"</label></div>"
        }
        
        const divHeader = "<div style='height: 41px; width: 100%; background-color: #ff86ac; color: white; padding-left: 15px;padding-top: 20px'><label style=' font-size: 25px; height: 100%; width:100%; vertical-align: text-top;'>&larr;</label><label style='font-size: 25px; padding-left: 15px;  height: 100%; width:100%; vertical-align: text-top;'>A Principal</label></div>";
        const divData = "<div style='padding-left: 16px;'><label style='font-size: 13px;font-family: Cambria;'>"+date+"</label></div>";

        const codigo = "<html "+noPadding+"><body "+noPadding+">"+divHeader+divImagem+divTitulo+divData+divTexto+"</body></html>";

        return (
            <div className="smartphone">
                <div className="content">
                    <iframe className="iframeStyle" srcDoc={codigo}></iframe>
                </div>
            </div>
        )
    }
}