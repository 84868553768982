import React from 'react';
import {
  Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink,
  } from 'reactstrap';
  import { Link } from 'react-router-dom';

  import Logo from '../../images/logo1.png';
  import './styles.css';

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  sair = (event) => {
    event.preventDefault();
    localStorage.clear();
    window.location.href = '/';
    
  }
 
  getItem () {

  }

  getRoot() {
    return localStorage.getItem("root");
  }

  render() {
    return (
      <div>
        <Navbar color="white" light expand="md">
          <Link to={'/'}><img id="imgLogo" src={Logo} alt="Logo" /></Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/clientes">Clientes</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/produtos/">Produtos</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/notificacao/">Notícias</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/catalogo/">Catálogos</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/relacionamento-direcionado/">Relacionamento</NavLink>
              </NavItem>
              {
                this.getRoot() == 'true' ? (
                <NavItem>
                  <NavLink href="/criar-usuario/">Usuários</NavLink>
                </NavItem>):('')
              }
              
              <NavItem>
                <NavLink href="/login/" onClick={this.sair}>Sair</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}


// import React from 'react';

// import {
//     Col,
//     Collapse,
//     Nav,
//     Navbar,
//     NavItem,
//     Row
// } from 'reactstrap';

//     import Logo from '../../images/logo1.png';
//     import './styles.css';

//     const Header = () => (
//         <div>
//             {/* <header color="white" light expand="md" id="componentHeader">
//             <img id="imgLogo" src={Logo} alt="Logo" />
//             </header> */}
//             <Navbar color="white" light expand="md" id="componentHeader">
//                 <Collapse isOpen="true" navbar>
//                     <Nav navbar>
//                         <Row>
//                             <Col>
//                             <NavItem id="NavItemNotification" className="navItem">
//                                 Enviar Notificações
//                             </NavItem>
//                             </Col>
//                             <Col>
//                                 <NavItem>
//                                     <img id="imgLogo" src={Logo} alt="Logo" />
//                                 </NavItem>
//                             </Col>
//                             <Col>
//                                 <NavItem id="NavItemAnalise" className="navItem">
//                                     Análise de Dados
//                                 </NavItem>
//                             </Col>
//                         </Row>
//                     </Nav>
//                 </Collapse>
//             </Navbar>
//         </div>
//     );

//     export default Header;