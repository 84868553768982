import React, { Component } from 'react';
import { Col, Row, } from 'reactstrap';
import Firebase from '../../Firebase';

import Header from '../../components/header/index';
import FormularioCatalogo from '../../components/formCatalogo/index';
import BottomTable from '../../components/bottomTabelCatalogo';

export default class Catalogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            image: null,
            fileBase64: null,
            imageFileBase64: null,
            listaCatalogos: [],
            catalogo: {
                title: "",
                fornecedor: "",
                fileDownloadUrl: "",
                imageFileDownloadUrl: ""
            }
        }
        this.fillCurrentList();
    }

    //Busca os dados da lista de sudsdgestões atual do firestore e salva no estado do componente
    fillCurrentList = async () => {
        var listaCatalogos = [];
        const db = Firebase.firestore();
        await db.collection("catalogos")
            .get().then(data => {
                data.forEach(doc => {

                    //console.log(doc.data());
                    
                    var dataFormatada = new Date(doc.data().dateNews.seconds * 1000);
                    
                    var ano = dataFormatada.getFullYear();
                    var mes = dataFormatada.getMonth()+1;
                    var dia = dataFormatada.getDate();
                    var hora = dataFormatada.getHours();
                    var minuto = dataFormatada.getMinutes();

                    if (mes < 10) {
                        mes = "0"+mes;
                    }
                    if (hora < 10) {
                        hora = "0"+hora;
                    }
                    if (minuto < 10) {
                        minuto = "0"+minuto;
                    }

                    var horaCompleta = dia+"/"+mes+"/"+ano+" - "+hora+":"+minuto;
                    //console.log(horaCompleta);
                    doc.data().data = horaCompleta;            
                    //console.log(doc.data());

                    var a = {
                        fileDownloadUrl: doc.data().fileDownloadUrl,
                        fornecedor: doc.data().fornecedor,
                        title: doc.data().title,
                        data: horaCompleta,
                        imageFileDownloadUrl: doc.data().imageFileDownloadUrl,
                        id: doc.id
                    }
                    listaCatalogos.push(a);

                })

                this.setState({
                    listaCatalogos
                });

            })
            .catch(err => {
                console.log("Error getting document", err);
            });
    }

    remover = async ( idCatalogo ) => {
        //console.log(idCatalogo);
        
        const db = await Firebase.firestore();
        db.collection("catalogos").doc(idCatalogo).delete()
        .then( () => {
            this.fillCurrentList();
        })
    }

    getFile = async event => {
        await this.setState({
            file: event.target.files[0]
        });
        var fileReaded = new FileReader();
        fileReaded.readAsDataURL(this.state.file);

        fileReaded.onload = (fileReaded) => {
            this.setState({
                fileBase64: fileReaded.target.result
            });
        }
    }

    getImage = async event => {
        await this.setState({
            image: event.target.files[0]
        });
        var fileReaded = new FileReader();
        fileReaded.readAsDataURL(this.state.image);

        fileReaded.onload = (fileReaded) => {
            this.setState({
                imageFileBase64: fileReaded.target.result
            });
        }
    }

    changeStatusCatalogo = () => {
        this.setState({
            file: null,
            fileBase64: null
        })
    }

    submitCatalogo = async (e) => {
        e.preventDefault();

        //Pegando os dados do formulário através do evento
        var title = e.target.titleCatalogo.value;
        var fornecedor = e.target.empresaCatalogo.value;
        var now = new Date();

        //Criando referência para o firebase storage para salvar o arquivo
        var storageRef = Firebase.storage().ref();
        var catalogosRef = storageRef.child('catalogos/' + e.target.titleCatalogo.value + '.pdf');
        var file = await this.state.file;
        await catalogosRef.put(file)
            .then(function (snapshot) {
                console.log("Uploaded");
            });

        //Retornando a url para acesso da imagem
        await catalogosRef.getDownloadURL().then(async url => {


            var storageRef2 = Firebase.storage().ref();
            var catalogosRef2 = storageRef2.child('imagensCatalogos/' + title + '.jpg');
            var file2 = await this.state.image;
            await catalogosRef2.put(file2)
            .then(function (snapshot) {
                console.log("Uploaded");
            });

            await catalogosRef2.getDownloadURL().then(async url2 => {
                //console.log(url2);
                await this.setState({
                    catalogo: {
                        title: title,
                        fornecedor: fornecedor,
                        fileDownloadUrl: url,
                        imageFileDownloadUrl: url2
                    }
                });
            })

        });

        const db = await Firebase.firestore();
        db.collection('catalogos').add({
            title: await this.state.catalogo.title,
            fornecedor: await this.state.catalogo.fornecedor,
            fileDownloadUrl: await this.state.catalogo.fileDownloadUrl,
            imageFileDownloadUrl: await this.state.catalogo.imageFileDownloadUrl,
            dateNews: now
        })
            .then(() => {
                //Resetando o formulário e limpando as variável do estado
                document.getElementById("formNotification").reset();
                this.setState({
                    file: null,
                    fileBase64: null,
                    imageFileBase64: null,
                    catalogo: {
                        title: "",
                        fornecedor: "",
                        fileDownloadUrl: "",
                        imageFileDownloadUrl: ""
                    }
                })
                this.fillCurrentList();
            })
            .catch(error => {
                console.log("Erro ao salvar os dados ", error);
            })
    }

    render() {
        return (
            <div>
                <Col xs="11" sm="12">
                    <Header />
                </Col>
                <Row>
                    <Col xs="12" sm="12">
                        <FormularioCatalogo getFile={this.getFile} getImage={this.getImage}
                        fileBase64={this.state.fileBase64} resetForm={this.changeStatusCatalogo}
                        handleSubmit={this.submitCatalogo} imageBase64={this.state.imageFileBase64}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12">
                        <BottomTable catalogos={this.state.listaCatalogos} 
                        removerCatalogo={this.remover}/>
                    </Col>
                </Row>
            </div>
        )
    }
}