import React, { Component } from 'react';
import { Row, Col, Form } from 'reactstrap';
import './styles.css';
import Logo from '../../images/aprincipal.png';
import Firebase from '../../Firebase';

export default class Login extends Component {
    
    verificaLogin = async (event) => {
        document.getElementById("inputEmail").style.boxShadow = "none";
        document.getElementById("inputSenha").style.boxShadow = "none";
        event.preventDefault();
        let senhareal;
        let loginreal;
        let root;

        let senhaDigitada = document.getElementById("inputSenha").value;
        let emailDigitado = document.getElementById("inputEmail").value;
        
        const db = Firebase.firestore();

        const adm = await db.collection('administrador').where('login', '==', emailDigitado).get()
        .then( snapshot => {
            if (snapshot.empty) {
                console.log('No matching documents.');
                return;
            }

            snapshot.forEach( doc => {
                senhareal = doc.data().senha;
                loginreal = doc.data().login;
                root = doc.data().root;
            })
        })
        
        
        if (loginreal === emailDigitado && senhareal === senhaDigitada) {
            await this.redirecionarMain(root);
        }else{
            document.getElementById("inputEmail").style.boxShadow = "0 0 3pt 2pt red";
            document.getElementById("inputSenha").style.boxShadow = "0 0 3pt 2pt red";
            document.getElementById("inputSenha").focus();            
        }
        
    }

    redirecionarMain = (root) => {
        window.localStorage.setItem("loged", "true");
        window.localStorage.setItem("root", root);

        window.location.href = '/clientes';
     
    }

    render(){
        return (
      
            <div className="content">
                <Row style={{height: "100px"}}>
                    <Col xs="12" lg="6" sm="6" style={{margin: "0 auto", backgroundColor: "#ff86ac", 
                    alignItems: "center", display: "flex", flexDirection: "row", flexWrap: "wrap",
                    justifyContent: "center"}}>
                        <div style={{textAlign: "center", color: "white", fontWeight: "bold", fontSize: "150%"}}>
                            Seja Bem Vinda!
                        </div>
                    </Col>
                </Row>
                <Row style={{height: "500px"}}>
                    <Col xs="12" lg="6" sm="6" style={{margin: "0 auto", backgroundColor: "#fdeff0"}}>
                        <Row style={{textAlign: "center", color: "white", fontWeight: "bold", fontSize: "150%",
                    justifyContent: "center"}}>
                            <img src={Logo} alt="" style={{borderRadius: "40%"}}></img>
                        </Row>
                        <Row style={{margin: "0 auto",textAlign: "center", justifyContent: "center"}}>
                            <Form style={{width: "70%"}}>
                                <Row style={{margin: "0 auto",textAlign: "center", justifyContent: "center",
                                marginBottom: "15px"}}>
                                    <input id="inputEmail" type="email" name="email" placeholder="seu_email@email.com" required
                                    style={{ borderRadius: "10px", width: "100%", height: "40px", fontSize: "25px"}}></input>
                                </Row>
                                <Row style={{margin: "0 auto",textAlign: "center", justifyContent: "center",
                                marginBottom: "25px"}}>
                                    <input id="inputSenha" type="password" name="senha" placeholder="sua_senha" required
                                     style={{ borderRadius: "10px", width: "100%", height: "40px", fontSize: "25px"}}></input>
                                </Row>
                                <Row style={{margin: "0 auto",textAlign: "center", justifyContent: "center"}}>
                                    <button type="submit" onClick={this.verificaLogin} style={{backgroundColor:"#ff86ac",
                                     borderRadius: "10px", width: "100%", height: "40px", fontSize: "25px",
                                     color: "white", fontWeight: "bold"}}>Login</button>
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </div>
      
        )
    }
}